import React from 'react';
import PropTypes from 'prop-types';
import { Statistic, Space, Card, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import config from 'react-global-configuration';
import AddCredits from './AddCredits/AddCredits';
import { useMedia } from '../../hooks/useMedia';

export default function Balance({ loading, profile, onMakePayment, region, crypto, emailVerified, onPaymentMade }) {
  const { balance, coupon = {}, account_status, upcoming_invoice = {}, risk_level, personal } = profile;
  const { duration_in_months, duration } = coupon;
  const couponDuration = duration_in_months ? `for ${duration_in_months} months` : duration;
  const { name: couponName } = coupon;
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  const statisticTitle = () => (
    <>
      <h2 className='h2'>{t('title.remainingCredits', 'Your remaining credits')}</h2>
      {isEmpty(upcoming_invoice) ? '' : (' (' + t('description.remainingCredits', 'after applying upcoming invoice') + ')')}
    </>
  );

  return <Space direction='vertical' size={32} align={isDesktop ? 'start' : 'unset'} className='first-space-item-full'>
    <Space className='space-items-full' align='stretch' wrap>
      <Row gutter={[16, 16]} className='row-space'>
        <Col xs={24} lg={8} xl={6} xxl={5}>
          <Card bordered={false} className='statistic-card'>
            <Statistic
              prefix='$'
              value={balance}
              precision={2}
              title={statisticTitle()}
              loading={loading}
            />
          </Card>
        </Col>
        {couponName && (
          <Col xs={24} lg={8} xl={6} xxl={5}>
            <Card bordered={false} className='statistic-card'>
              <Statistic
                title={<h2 className='h2'>{t('title.specialOffer', 'Special Offer')}</h2>}
                value={couponName}
                suffix={couponDuration}
              />
            </Card>
          </Col>
        )}
      </Row>
    </Space>
    <Space direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'>{t('title.addCredits', 'Top Up')}</h3>
      <AddCredits loading={loading} onMakePayment={onMakePayment} region={region} crypto={crypto} riskLevel={risk_level} emailVerified={emailVerified} personal={personal} onPaymentMade={onPaymentMade} />
      {account_status === 'no_default_source' && <>
        <p className='main-text'>
        * <Trans i18nKey='description.usingCredits'>Use your deposit for any services at listed <a href={`${config.get('pricingUrl')}`} target='_blank' rel='noopener noreferrer'>prices</a>, with no expiration</Trans>.
        </p>
      </>}
    </Space>
  </Space>;
}

Balance.propTypes = {
  loading: PropTypes.bool,
  region: PropTypes.string,
  crypto: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  isTenant: PropTypes.bool,
  onMakePayment: PropTypes.func.isRequired,
  emailVerified: PropTypes.bool,
  onPaymentMade: PropTypes.func,
};
