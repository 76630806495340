export const PHONE_INPUT_FIELDS = ['phone', 'phone_1', 'phone_2', 'phone_3', 'phone_number'];
const EMAIL_INPUT_FIELDS = ['email', 'email_1', 'email_2', 'email_3', 'email_address'];
const DOMAIN_INPUT_FIELDS = ['site', 'domain', 'website'];
const BIZ_DESCRIPTION = 'Returns data from a list of businesses';
const PROFILE_DESCRIPTION = 'Returns data from a list of profiles';
const PHONE_CHEKER_ESCRIPTION = 'Checks whether a phone is connected to the service';
export const serviceGroups = {
  email: 'Best for cold emails',
  phone: 'Best for cold calls',
  other: 'Others',
};

export const newServices = {
  'airbnb-reviews': 1717057463,
  'glassdoor-reviews': 1717840674,
  'indeed-reviews': 1717840674,
  'fastbackgroundcheck-addresses': 1717840674,
  'expedia-reviews': 1718896779,
  'expedia-search': 1725608403,
  'getapp-reviews': 1718896779,
  'g2-reviews': 1725608403,
  'target-reviews': 1719592536,
  'homedepot-reviews': 1720467757,
  'walmart-reviews': 1720467757,
  'tripadvisor-search': 1720951495,
  'shopify-products': 1720951495,
  'airbnb-search': 1726302687,
  'yelp-photos': 1726302687,
  'screenshoter': 1727343715,
};

export const defaultWebsiteEnrichments = ['domains_service', 'emails_validator_service', 'company_insights_service'];
export const defaultPhoneEnrichments = ['whitepages_phones'];

export function checkIfNewService(key, recentNewServicesArray) {
  if (key in newServices && !recentNewServicesArray.includes(key)) {
    return ((Date.now() / 1000 - newServices[key]) < 4 * 7 * 24 * 60 * 60);
  } else {
    return false;
  }
}

export const services = {
  company_insights_service: {
    name: 'Company Insights',
    description: 'Finds company details such as revenue, size, founding year, public status, etc',
    prices: [{ 'up_to': 500, 'unit_amount_decimal': 0 }, { 'up_to': 100000, 'unit_amount_decimal': 0.005 }, { 'up_to': null, 'unit_amount_decimal': 0.003  }],
    unit: 'company',
    input_fields: DOMAIN_INPUT_FIELDS,
    output_fields: ['name', 'country', 'state', 'city', 'address', 'zip', 'timezone', 'is_public', 'founded_year', 'employees', 'revenue', 'total_money_raised', 'industry', 'description', 'linkedin_bio', 'phone', 'twitter_handle', 'facebook_company_page', 'linkedin_company_page'],
    groups: ['email'],
    isRecommended: true,
  },
  screenshoter: {
    name: 'WebPage Screenshoter',
    description: 'Captures high-quality screenshots of any webpage simply by providing its URL',
    prices: [{ 'up_to': 10, 'unit_amount_decimal': 0 }, { 'up_to': 10000, 'unit_amount_decimal': 0.03 }, { 'up_to': null, 'unit_amount_decimal': 0.01 }],
    unit: 'page',
    input_fields: [],
    output_fields: ['screenshot'],
    groups: ['other'],
  },
  domains_service: {
    name: 'Emails & Contacts Scraper',
    description: 'Finds emails, social links, phones, and other contacts from websites',
    prices: [{ 'up_to': 500, 'unit_amount_decimal': 0 }, { 'up_to': 100000, 'unit_amount_decimal': 0.003 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'domain',
    icon: '📧',
    input_fields: DOMAIN_INPUT_FIELDS,
    output_fields: [
      'email_1', 'email_1_full_name', 'email_1_first_name', 'email_1_last_name', 'email_1_title', 'email_1_phone',
      'email_2', 'email_2_full_name', 'email_2_first_name', 'email_2_last_name', 'email_2_title', 'email_2_phone',
      'email_3', 'email_3_full_name', 'email_3_first_name', 'email_3_last_name', 'email_3_title', 'email_3_phone',
      'phone_1', 'phone_2', 'phone_3',
      'facebook', 'instagram', 'linkedin', 'tiktok', 'medium', 'reddit', 'skype', 'snapchat', 'telegram', 'whatsapp', 'twitter', 'vimeo', 'youtube', 'github', 'crunchbase',
      'website_title', 'website_generator', 'website_description', 'website_keywords', 'website_has_fb_pixel', 'website_has_google_tag',
    ],
    output_ontologies: ['email', 'phone'],
    groups: ['email'],
    incompatible_with: ['contacts_finder_service'],
    isRecommended: true,
    hasFilters: true,
    hasParameters: true
  },
  emails_validator_service: {
    name: 'Email Address Verifier',
    description: 'Validates emails, checks deliverability, filters out blacklists, spam traps, and complainers, while significantly reducing your bounce rate',
    prices: [{ 'up_to': 100, 'unit_amount_decimal': 0 }, { 'up_to': 100000, 'unit_amount_decimal': 0.003 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'email',
    input_fields: EMAIL_INPUT_FIELDS,
    output_fields: ['status', 'status_details'],
    groups: ['email'],
    isRecommended: true,
  },
  disposable_email_checker: {
    name: 'Disposable Emails Checker',
    description: 'Checks origins of email addresses (disposable, free, or corporate)',
    prices: [{ 'up_to': 1000, 'unit_amount_decimal': 0 }, { 'up_to': 10000, 'unit_amount_decimal': 0.0003 }, { 'up_to': null, 'unit_amount_decimal': 0.0001 }],
    unit: 'email',
    input_fields: EMAIL_INPUT_FIELDS,
    output_fields: ['is_disposable', 'is_free_email'],
    groups: ['email'],
  },
  whitepages_phones: {
    name: 'Phone Identity Finder',
    description: 'Returns insights about phone number owners (name, address, etc.)',
    prices: [{ 'up_to': 500, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.003 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'phone',
    input_fields: PHONE_INPUT_FIELDS,
    output_fields: ['name', 'lookup_type', 'person_id', 'phone_type', 'address'],
    supported_regions: ['US'],
    groups: ['phone'],
    isRecommended: true,
  },
  ai_chain_info: {
    name: 'Chain Info',
    description: 'Identifies if a business is part of a chain, adding a true/false indication to your data for smarter targeting',
    prices: [{ 'up_to': 50, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.005 }, { 'up_to': null, 'unit_amount_decimal': 0.003  }],
    unit: 'company',
    output_fields: ['chain'],
    groups: ['other'],
    supported_services: ['google_maps_service_v2'],
  },
  contacts_finder_service: {
    name: 'Contacts Finder',
    description: 'Finds employees contacts (email, phone, full name, title, etc.)',
    prices: [{ 'up_to': 500, 'unit_amount_decimal': 0 }, { 'up_to': 100000, 'unit_amount_decimal': 0.003 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'contact',
    icon: '👤',
    input_fields: DOMAIN_INPUT_FIELDS,
    output_fields: [
      'company_domain', 'email', 'phone', 'full_name', 'first_name', 'last_name', 'title', 'level', 'linkedin', 'emails', 'phones',
    ],
    output_ontologies: ['email', 'phone'],
    groups: ['email'],
    incompatible_with: ['domains_service'],
    hasParameters: true
  },
  phones_enricher_service: {
    name: 'Phone Numbers Enricher',
    description: 'Returns phones carrier data (name/type), validates phones, ensures messages deliverability',
    prices: [{ 'up_to': 25, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.005 }, { 'up_to': null, 'unit_amount_decimal': 0.003 }],
    unit: 'phone',
    input_fields: PHONE_INPUT_FIELDS,
    output_fields: ['carrier_name', 'carrier_type'],
    groups: ['phone'],
  },
  whatsapp_checker: {
    name: 'WhatsApp Checker',
    description: PHONE_CHEKER_ESCRIPTION,
    prices: [{ 'up_to': 5, 'unit_amount_decimal': 0 }, { 'up_to': 5000, 'unit_amount_decimal': 0.015 }, { 'up_to': null, 'unit_amount_decimal': 0.01 }],
    unit: 'phone',
    input_fields: PHONE_INPUT_FIELDS,
    output_fields: ['connected'],
    groups: ['phone'],
  },
  whitepages_addresses: {
    name: 'Whitepages Addresses Scraper',
    description: 'Returns insights about addresses and their residents',
    prices: [{ 'up_to': 50, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.003 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'address',
    input_fields: ['full_address', 'address'],
    output_fields: ['building_type', 'full_address', 'residents_count', 'resident_1_first_name', 'resident_1_last_name', 'resident_1_age_range', 'resident_1_phones', 'resident_2_first_name', 'resident_2_last_name', 'resident_2_age_range', 'resident_2_phones', 'resident_3_first_name', 'resident_3_last_name', 'resident_3_age_range', 'resident_3_phones'],
    supported_regions: ['US'],
    groups: ['other'],
  },
  fastbackgroundcheck_addresses: {
    name: 'Fastbackgroundcheck Addresses Scraper',
    description: 'Returns insights about addresses and their residents',
    prices: [{ 'up_to': 50, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.003 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'address',
    input_fields: ['full_address', 'address'],
    output_fields: [],
    supported_regions: ['US'],
    groups: ['other'],
  },
  // imessage_checker: {
  //   name: 'iMessage Checker',
  //   description: PHONE_CHEKER_ESCRIPTION,
  //   prices: [{ 'up_to': 5, 'unit_amount_decimal': 0 }, { 'up_to': 5000, 'unit_amount_decimal': 0.009 }, { 'up_to': null, 'unit_amount_decimal': 0.007 }],
  //   unit: 'phone',
  //   input_fields: PHONE_INPUT_FIELDS,
  //   output_fields: ['connected'],
  // },
  trustpilot_service: {
    name: 'Trustpilot Scraper',
    description: BIZ_DESCRIPTION,
    prices: [{ 'up_to': 100, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.003 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'domain',
    input_fields: DOMAIN_INPUT_FIELDS,
    output_fields: ['name', 'rating', 'reviews', 'site', 'profile_image', 'page_url', 'claimed', 'closed', 'temporarily_closed', 'categories', 'email', 'phone', 'address', 'city', 'country', 'zip_code'],
    output_ontologies: ['email', 'phone'],
    groups: ['other'],
  },
  reverse_geocoding: {
    name: 'Reverse Geocoding',
    description: 'Translates locations on the map into human-readable addresses',
    prices: [{ 'up_to': 1000, 'unit_amount_decimal': 0 }, { 'up_to': 100000, 'unit_amount_decimal': 0.0003 }, { 'up_to': null, 'unit_amount_decimal': 0.0001 }],
    unit: 'search',
    input_fields: ['coordinates', ['latitude', 'longitude'], ['lat', 'long']],
    output_fields: ['country_code', 'state', 'city', 'county', 'street', 'address', 'postal_code', 'plus_code', 'photo', 'time_zone'],
    output_ontologies: ['address'],
    groups: ['other'],
  },
  geocoding: {
    name: 'Geocoding',
    description: 'Translates human-readable addresses into locations on the map (latitude, longitude)',
    prices: [{ 'up_to': 500, 'unit_amount_decimal': 0 }, { 'up_to': 100000, 'unit_amount_decimal': 0.002 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'search',
    icon: '📍',
    input_fields: ['address', ['state', 'city', 'county', 'street', 'postal_code']],
    output_fields: ['latitude', 'longitude', 'country', 'state', 'city', 'borough', 'street', 'postal_code', 'time_zone', 'street_view'],
    output_ontologies: [],
    groups: ['other'],
  },
  youtube_channel_service: {
    name: 'YouTube Channels Scraper',
    description: PROFILE_DESCRIPTION,
    prices: [{ 'up_to': 25, 'unit_amount_decimal': 0 }, { 'up_to': 5000, 'unit_amount_decimal': 0.005 }, { 'up_to': null, 'unit_amount_decimal': 0.003 }],
    unit: 'profile',
    input_fields: ['channel_url', 'youtube_channel'],
    output_fields: [],
    output_ontologies: [],
    groups: ['other'],
  },
  yellowpages_service: {
    name: 'Yellow Pages Search Scraper',
    description: BIZ_DESCRIPTION,
    prices: [{ 'up_to': 500, 'unit_amount_decimal': 0 }, { 'up_to': 5000, 'unit_amount_decimal': 0.002 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'biz',
    input_fields: [],
    output_fields: ['name', 'categories', 'position', 'years_in_business', 'years_with_yp', 'amenities', 'phone', 'street', 'locality', 'site', 'snippet', 'thumbnail', 'range', 'business_link'],
    output_ontologies: ['domain', 'phone'],
    groups: ['other'],
  },
  trustpilot_search_service: {
    name: 'Trustpilot Search Scraper',
    description: 'Returns search results',
    prices: [{ 'up_to': 100, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.003 }, { 'up_to': null, 'unit_amount_decimal': 0.001 }],
    unit: 'company',
    output_fields: ['name', 'rating', 'reviews', 'site', 'profile_image', 'page_url', 'claimed', 'closed', 'temporarily_closed', 'categories', 'email', 'phone', 'address', 'city', 'country', 'zip_code'],
    output_ontologies: ['email', 'domain', 'phone'],
    groups: ['other'],
  },
  linkedin_companies_service: {
    name: 'Linkedin Companies Scraper',
    description: BIZ_DESCRIPTION,
    prices: [{ 'up_to': 50, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.005 }, { 'up_to': null, 'unit_amount_decimal': 0.003 }],
    unit: 'company',
    input_fields: ['linkedin', 'linkedin_company', 'linkedin_company_page', 'linkedin_company_url'],
    output_fields: ['name', 'headline', 'subline', 'about', 'website', 'industries', 'size', 'employees_on_linkedin', 'headquarters', 'type', 'founded', 'specialties', 'url'],
    output_ontologies: ['email', 'domain'],
    groups: ['other'],
  },
  linkedin_profiles: {
    name: 'Linkedin Profiles Scraper',
    description: PROFILE_DESCRIPTION,
    prices: [{ 'up_to': 50, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.005 }, { 'up_to': null, 'unit_amount_decimal': 0.003 }],
    unit: 'profile',
    input_fields: ['linkedin', 'linkedin_profile', 'linkedin_profile_page',  'linkedin_profile_url'],
    output_fields: [],
    output_ontologies: [],
    groups: ['other'],
  },
  linkedin_posts: {
    name: 'Linkedin Posts Scraper',
    description: 'Returns posts from a list of profiles',
    prices: [{ 'up_to': 50, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.005 }, { 'up_to': null, 'unit_amount_decimal': 0.003 }],
    unit: 'post',
    input_fields: ['linkedin', 'linkedin_company', 'linkedin_company_page', 'linkedin_company_url'],
    output_fields: [],
    output_ontologies: [],
    groups: ['other'],
  },
  companies_data: {
    name: 'US Companies Data Enricher',
    description: 'Enriches places with legal names, phones, NAICS, SIC, employees number, sales volume, etc',
    prices: [{ 'up_to': 500, 'unit_amount_decimal': 0 }, { 'up_to': 50000, 'unit_amount_decimal': 0.005 }, { 'up_to': null, 'unit_amount_decimal': 0.003 }],
    unit: 'company',
    output_fields: [
      'company_name', 'legal_name', 'business_type', 'company_year_started', 'naics', 'naics_desc', 'sic6code', 'sic6desc', 'business_specialty',
      'state_where_entity_formed', 'ein', 'public', 'chain', 'franchise', 'home_office', 'parent_company',
      'telephone', 'telephone2', 'fax', 'toll_free_number', 'owner_phone', 'ethnicity',
      'url',
      'sales_volume', 'sales_code', 'company_sales', 'company_sales_code', 'number_of_employees', 'employee_code', 'company_num_emp',
    ],
    supported_regions: ['US'],
    supported_services: ['google_maps_service_v2'],
    groups: ['other'],
  }
};
