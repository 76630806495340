import React from 'react';
import { Col, Form, Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { usStates } from '../../shared/data/regions';
import SimpleService from './Common/SimpleService';
import RegionSelect from './Common/Google/RegionSelect';
import { defaultWebsiteEnrichments, defaultPhoneEnrichments } from '../../shared/data/services';

const { Option } = Select;

export default function PlacesByDomainsSearch() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='domain'
      defaultLimit={1}
      title='Google Maps Search by Domains'
      subTitle='Finds places by domains'
      serviceName='google_places_domain_search_service'
      queriesPlaceholder='dominopark.com&#10;https://www.esbnyc.com/'
      queriesLabel='Domains or URLs'
      apiTag='Google/paths/~1google-places-by-domain/get'
      supportedEnrichmentServices={[]}
      serviceOntologies={['domain', 'phone', 'address']}
      defaultEnrichments={[ ...defaultWebsiteEnrichments, ...defaultPhoneEnrichments ]}
      ExtraItems={({ updateTask, task }) => {
        const { region, state } = task;

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={12} xl={8}>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Form.Item label={t('title.country', 'Country')}>
                  <RegionSelect size='default' value={region} onChange={(value) => updateTask({ region: value })}/>
                </Form.Item>
              </Col>
              {region === 'US' &&
              <Col xs={24} lg={12}>
                <Form.Item label={t('title.state', 'State')}>
                  <Select
                    allowClear
                    showSearch
                    size='default'
                    value={state}
                    onChange={(value) => updateTask({ state: value })}
                    optionFilterProp='key'
                  >
                    {usStates.map(r => <Option key={`${r.code}-${r.state}`} value={r.code}>{r.state}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              }
            </Row>
          </Col>
        </Space>;
      }}
    />
  );
}
