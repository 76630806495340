import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Popconfirm, message, Space, Dropdown, notification } from 'antd';
import {
  RedoOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  CopyOutlined,
  LinkOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { encode } from 'js-base64';
import { useDispatch } from 'react-redux';

import Mixpanel from '../../../analytics/mixpanel';
import { isGoogleIntegrationExists, isPaidUser } from '../../../utils/utils';
import PremiumFeatureTooltip from '../../../shared/Components/PremiumFeatureTooltip';
import { GoogleSheets, MenuKebab } from '../../../assets/icons';
import { connectGoogleSheetsAndReturnCredentials } from '../../../redux/actions/profileActions';
import { exportToSpreadsheet } from '../../../redux/actions/tasksActions';
import { dynamicallyCreateAnchorAndNavigate } from '../../../utils/helpers/openInNewTab';
import './Tasks.scss';

const StyledButton = styled(Button)`
  margin: 0 2px;
  width: 10px;
`;
StyledButton.defaultProps = { type: 'default', size: 'small', shape: 'round' };

const supportedExtensions = new Set(['csv', 'xlsx']);

export default function TaskActions({ task = {}, onRerun = null, onRecreate = null, onRemove = null, onSchedule = null }) {
  const { id, metadata = {}, status, created, results = [] } = task;
  const firstResult = results[0] || {};
  const { spreadsheet_url, quantity = 0 } = firstResult;
  const fileUrl = firstResult.file_url || '';
  const isUsage = !!quantity && quantity > 0;

  const restarted = status === 'RESTARTED';
  const isSuccess = status === 'SUCCESS';
  const isRerunnable = (metadata.queries || (metadata.locations && metadata.categories) && !restarted);
  const isOldTask = !restarted && Math.abs(new Date() - new Date(created)) / 36e5 > 24;
  const paidUser = isPaidUser();
  const { t } = useTranslation();

  const { settings = {} } = metadata;
  const { output_extension } = settings;
  const isSupportedExtension = supportedExtensions.has(output_extension);

  const dispatch = useDispatch();

  function handleOnRecreate() {
    onRecreate(metadata);
  }

  function handleOnRerun() {
    onRerun(id);
  }

  function handleOnSchedule() {
    onSchedule(id);
  }

  function handleOnRemove() {
    onRemove(id);
  }

  function handleShare() {
    Mixpanel.track('Share task');

    navigator.clipboard.writeText(`${window.origin}/shared/${encode(localStorage.getItem('id'))}/${id}`);

    message.success('The public link to the task is copied to your clipboard');
  }

  async function handleOpenSpreadsheet() {
    try {
      const credentials = isGoogleIntegrationExists() ? {} : await connectGoogleSheetsAndReturnCredentials();
      const newSpreadsheetUrl = await dispatch(exportToSpreadsheet(id, credentials.access_token, credentials.refresh_token));

      if (newSpreadsheetUrl) {
        notification.success({
          message: <a href={newSpreadsheetUrl} target='_blank' rel='noopener noreferrer'>Open on Google Sheets</a>,
          duration: 30, style: { width: 600 }
        });
        dynamicallyCreateAnchorAndNavigate(newSpreadsheetUrl);
      } else {
        console.error('Failed to upload file:', newSpreadsheetUrl);
      }
    } catch (error) {
      console.error('Error exporting file:', error);
    }
  }

  const moreActions = (
    <Space direction='vertical' className='more-actions'>
      <Button
        className='share-task'
        icon={<LinkOutlined />}
        disabled={!isSuccess || !isUsage}
        onClick={handleShare}
      >
        {t('action.share', 'Share')}
      </Button>
      {onRerun &&
          <Popconfirm
            title={t('action.restart', 'Restart') + '?'}
            okText={t('action.ok', 'Ok')}
            cancelText={t('action.cancel', 'Cancel')}
            onConfirm={handleOnRerun}
            disabled={!isRerunnable}
          >
            <Button
              className='restart-task'
              icon={<RedoOutlined />}
              disabled={!isRerunnable || restarted || (!isOldTask && !isSuccess)}
            >
              {t('action.restart', 'Restart')}
            </Button>
          </Popconfirm>
      }
      {onSchedule &&
          <PremiumFeatureTooltip hideIcon isPaidUser={paidUser}>
            <Button
              className='schedule-task'
              icon={<ClockCircleOutlined />}
              onClick={handleOnSchedule}
              disabled={!isRerunnable || !paidUser}
            >
              {t('action.schedule', 'Schedule')}
            </Button>
          </PremiumFeatureTooltip>
      }
      {onRecreate &&
          <Button
            className='copy-task'
            icon={<CopyOutlined />}
            disabled={!isRerunnable}
            onClick={handleOnRecreate}
          >
            {t('action.useAsTemplate', 'Use as a template')}
          </Button>
      }
      {onRemove &&
          <Popconfirm
            title={t('action.remove', 'Remove') + '?'}
            okText={t('action.ok', 'Ok')}
            cancelText={t('action.cancel', 'Cancel')}
            onConfirm={handleOnRemove}
          >
            <Button
              className='remove-task'
              icon={<CloseCircleOutlined />}
              disabled={restarted || (!isOldTask && !isSuccess)}
            >
              {t('action.remove', 'Remove')}
            </Button>
          </Popconfirm>
      }
    </Space>
  );

  return <Space size={4} className='task-actions'>
    <StyledButton
      title='Open table in Google Sheets'
      disabled={!isSuccess || !isSupportedExtension || quantity <= 0 || quantity > 10000}
      icon={<GoogleSheets/>}
      href={spreadsheet_url || undefined}
      target={spreadsheet_url ? '_blank' : undefined}
      onClick={spreadsheet_url ? undefined : handleOpenSpreadsheet}
    />
    <StyledButton
      type='primary'
      title={t('action.upload', 'Upload')}
      disabled={!isUsage}
      icon={<DownloadOutlined/>}
      href={fileUrl}
    />
    <Dropdown overlay={moreActions} trigger={['click']}>
      <StyledButton
        className='more-actions'
        title='Open more'
        icon={<MenuKebab />}
      />
    </Dropdown>
  </Space>;
}

TaskActions.propTypes = {
  task: PropTypes.object.isRequired,
  onRerun: PropTypes.func,
  onRecreate: PropTypes.func,
  onRemove: PropTypes.func,
  onSchedule: PropTypes.func,
  onShare: PropTypes.func,
};
