import React from 'react';
import { Form, Input, InputNumber, Checkbox, Space, Select, Col, Row } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import { formatTaskQueries, deformatTaskQueries } from './Common/Google/utils';
import { getDefaultLanguage } from '../../utils/defaultProps';
import BaseService from './Common/BaseService';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import AdvancedParametersPanel from './Common/AdvancedParametersPanel';
import PremiumFeatureTooltip from '../../shared/Components/PremiumFeatureTooltip';
import QueriesFormItems, { getDefaultIsCustomCategories, getDefaultIsCustomLocations } from './Common/Google/QueriesFormItems';
import LanguageSelect from './Common/Google/LanguageSelect';
import RegionSelect from './Common/Google/RegionSelect';
import ReviewsSortingFields from './Common/Google/MapsReviewsSortingFields';
import OrganizationsPerQueryInput from './Common/Google/OrganizationsPerQueryInput';
import { enrichmentFields } from '../../shared/constants/googleMaps.constants';
import { validateCategoryLocationExpanded } from '../../utils/validation';

const { Option } = Select;
const CUSTOM_QUERIES_KEY = 'reviews/customQueries';
const COLUMNS = ['query', 'name', 'google_id', 'place_id', 'location_link', 'reviews_link', 'reviews_per_score', 'reviews', 'rating', 'review_id', 'review_pagination_id', 'author_link', 'author_title', 'author_id', 'author_image', 'review_text', 'review_img_url', 'review_img_urls', 'owner_answer', 'owner_answer_timestamp', 'owner_answer_timestamp_datetime_utc', 'review_link', 'review_rating', 'review_timestamp', 'review_datetime_utc', 'review_likes', 'reviews_id'];

export default function GoogleMapsReviewsExtractor({ version }) {
  const location = useLocation();
  const { hl: urlLanguage, query: urlQuery, gl: urlRegion } = queryString.parse(location.search);
  const taskExtraDefaultParams = {
    categories: [],
    locations: [],

    customCategories: '',
    customLocations: '',

    language: getDefaultLanguage(urlLanguage),
    region: (urlRegion && urlRegion !== 'undefined') ? urlRegion : undefined,

    reviewsPerOrganizationLimit: 250,
    organizationsPerQueryLimit: 1,
    skip: 0,

    sort: 'newest',
    cutoffRating: null,
    reviews_query: null,
    ignore_empty: false,
    source: null,

    dropDuplicates: true,
    ignoreWithoutEmails: false,

    UISettings: {
      isCustomQueries: urlQuery ? true : (localStorage.getItem(CUSTOM_QUERIES_KEY) ? localStorage.getItem(CUSTOM_QUERIES_KEY) === '1' : true),
      isCustomCategories: getDefaultIsCustomCategories(),
      isCustomLocations: getDefaultIsCustomLocations(),
    },
  };
  const { t } = useTranslation();

  function formatTask(task) {
    const { start, cutoff, enrich, UISettings } = task;
    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

    if (enrich) {
      task.organizationsPerQueryLimit = 1;
      task.region = undefined;
    }

    return formatTaskQueries(
      { ...task, start: start ? start.unix() : null, cutoff: cutoff ? cutoff.unix() : null }
      , isCustomQueries, isCustomCategories, isCustomLocations);
  }

  function deformatTask(task) {
    const { start, cutoff, UISettings = {} } = task;
    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

    return deformatTaskQueries(
      { ...task, start: start ? dayjs(start*1000) : null, cutoff: cutoff ? dayjs(cutoff*1000) : null },
      isCustomQueries, isCustomCategories, isCustomLocations);
  }

  return (
    <BaseService
      title='Google Maps Reviews Scraper'
      subTitle='Returns reviews from places on Google Maps'
      videoTutorialLink='https://www.youtube.com/embed/1_BjMtLrrIg'
      tutorialLink='https://outscraper.com/how-to-scrape-google-maps-reviews'
      serviceName={version === 3 ? 'google_maps_reviews_service_v3' : 'google_maps_reviews_service_v2'}
      startTaskButtonTitle='Export Reviews'
      unitName='review'
      learnMoreUrl='https://outscraper.com/google-maps-reviews-scraper'
      apiTag='Google/paths/~1maps~1reviews-v3/get'
      taskExtraDefaultParams={taskExtraDefaultParams}
      validateTask={validateCategoryLocationExpanded}
      formatTask={formatTask}
      deformatTask={deformatTask}
      taskUpdateAfterSubmit={{ queries: '', categories: [], customCategories: '', input_file: null, enrich: false, tags: [] }}
      tourSteps={[
        {
          target: () => document.querySelector('.queries-input'),
          title: <Trans i18nKey='tour.queriesGReviewsInput'>First of all, specific place(s) you want to get reviews from.<br/><br/>You can use anything that you would use on the Google Maps site. Additionally, you can use <em>google_id</em> (feature_id), <em>place_id</em>, <em>CID</em> or links.</Trans>,
        },
        {
          target: () => document.querySelector('.sorting-select'),
          title: t('tour.sortingSelect', 'Secondly, select one of the sorting types.'),
        },
        {
          target: () => document.querySelector('.limit-select'),
          title: t('tour.limitGReviewsSelect', 'Here you can define the maximum number of reviews to retrieve for a single place and the maximum number of places to obtain from one query.'),
        },
      ]}
      fields={COLUMNS}
      FormBody={({ task, updateTask, invalidFields }) => {
        const { UISettings } = task;
        const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

        function setIsCustomCategories(isCustomCategories) {
          updateTask({ UISettings: { ...UISettings, isCustomCategories } });
        }

        function setIsCustomLocations(isCustomLocations) {
          updateTask({ UISettings: { ...UISettings, isCustomLocations } });
        }

        return <>
          <QueriesFormItems
            task={task}
            onUpdate={updateTask}
            isCustomQueries={isCustomQueries}
            isCustomCategories={isCustomCategories}
            isCustomLocations={isCustomLocations}
            onIsCustomCategoriesChange={setIsCustomCategories}
            onIsCustomLocationsChange={setIsCustomLocations}
            extraHint='In case no reviews were found by your query, the query will still consume the usage of one review.'
            customQueriesKey={CUSTOM_QUERIES_KEY}
            enrichmentFields={enrichmentFields}
            invalidFields={invalidFields}
          />
        </>;
      }}
      FormBodyExtra={({ task, updateTask, isPaidUser }) => {
        const { language, region, source, enrich, organizationsPerQueryLimit, reviewsPerOrganizationLimit, reviews_query, ignore_empty, UISettings } = task;
        const { isCustomQueries, isCustomLocations } = UISettings;

        function onSetLanguage(value) {
          updateTask({ language: value });
          localStorage.setItem('language', value);
        }

        return <Space direction='vertical' size={16} className='w-100'>
          <ReviewsSortingFields task={task} onChange={updateTask} isPaidUser={isPaidUser} />

          <Col xs={24}>
            <Form.Item
              label={t('title.reviewsLimit', 'Reviews limit per one place (0 = unlimited)')}
              wrapperCol={{
                xs: { span: 24 },
                lg: { span: 6 },
                xl: { span: 4 },
              }}
            >
              <Space align='center' className='first-space-item-full'>
                <InputNumber
                  className='w-100'
                  min={0}
                  step={100}
                  value={reviewsPerOrganizationLimit}
                  onChange={(v) => updateTask({ reviewsPerOrganizationLimit: parseInt(v) || 0 })}
                />
                <InfoTooltip title={t('description.reviewsLimit', 'Parameter specifies the limit of reviews to extract for one place')}/>
              </Space>
            </Form.Item>
          </Col>

          {!enrich &&
                <Col xs={24} lg={6} xl={4}>
                  <Form.Item label={t('title.organizationsPerQueryLimit', 'Places per one query search')}>
                    <OrganizationsPerQueryInput
                      value={organizationsPerQueryLimit}
                      onChange={(v) => updateTask({ organizationsPerQueryLimit: v })}
                    />
                  </Form.Item>
                </Col>}

          {/* <Form.Item label={t('title.Skip', 'Skip')}>
            <Space align='center' className='nobreak'>
              <InputNumber
                min={0}
                step={1000000}
                value={skip}
                onChange={(v) => updateTask({ skip: parseInt(v) || 0 })}
              />
              <InfoTooltip title={t('description.skip', 'Parameter specifies the number of items to skip. Commonly used in pagination')}/>
            </Space>
          </Form.Item> */}

          <AdvancedParametersPanel>
            <Col xs={24} lg={12} xl={8}>
              <Form.Item>
                <Row gutter={[16, 16]}>
                  <Col xs={24} lg={12}>
                    <Form.Item label={t('title.language', 'Language')}>
                      <LanguageSelect onChange={onSetLanguage} value={language}/>
                    </Form.Item>
                  </Col>
                  {(isCustomLocations || isCustomQueries) && !enrich &&
                  <Col xs={24} lg={12}>
                    <Form.Item label={t('title.country', 'Country')}>
                      <RegionSelect value={region} onChange={(v) => updateTask({ region: v })}/>
                    </Form.Item>
                  </Col>
                  }
                </Row>
              </Form.Item>
            </Col>

            <Col xs={24} lg={12} xl={8}>
              <Form.Item>
                <Row gutter={[16, 16]}>
                  <Col xs={24} lg={12}>
                    <Form.Item label={t('title.reviewsQuery', 'Reviews query search')}>
                      <Space align='center' className='nobreak first-space-item-full'>
                        <Input
                          placeholder='Amazing | great'
                          value={reviews_query}
                          onChange={({ target: { value } }) => updateTask({ reviews_query: value })}
                        />
                        <InfoTooltip title={t('description.reviewsQuery', 'The query to search among the reviews (e.g. `wow`, `amazing | great`). To split keywords you can use a vertical bar "|"')}/>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label={t('title.filtering', 'Filtering')}>
                      <Select
                        value={source}
                        onChange={(v) => updateTask({ source: v })}
                      >
                        <Option key='all' value={null}><Trans i18nKey='title.any'>Any</Trans></Option>
                        <Option key='google' value='google'>Google</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>

            <Form.Item>
              <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                <Space align='center' className='nobreak'>
                  <Checkbox
                    disabled={!isPaidUser}
                    checked={ignore_empty}
                    onChange={() => updateTask({ ignore_empty: !ignore_empty })}
                  ><Trans i18nKey='action.ignoreWithoutText'>Ignore reviews without text</Trans></Checkbox>
                  <InfoTooltip
                    title={<Trans i18nKey='description.ignoreWithoutText'>Exclude reviews without text from the results</Trans>}
                  />
                </Space>
              </PremiumFeatureTooltip>
            </Form.Item>
          </AdvancedParametersPanel>
        </Space>;
      }}
    />
  );
}

GoogleMapsReviewsExtractor.propTypes = {
  version: PropTypes.number,
};
