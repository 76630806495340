import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, DatePicker, Select, Space, Col } from 'antd';
import dayjs from 'dayjs';

import ReviewsSortSelect from '../Selects/ReviewsSortSelect';
import InfoTooltip from '../../../../shared/Components/InfoTooltip';

const { Option } = Select;

export const reviewsSorts = [
  { value: 'most_relevant', text: 'Most relevant' },
  { value: 'newest', text: 'Newest' },
  { value: 'rating', text: 'Rating' },
];

export default function ReviewsSortingFields({ task, onChange }) {
  const { cutoff, rating, sort } = task;

  const useCutoff = cutoff !== null;

  function disabledDate(current) {
    return current && current > dayjs();
  }

  function onSetUseCutoff() {
    onChange({ ...task, cutoff: cutoff ? null : dayjs().startOf('day') });
  }

  function onSetCutoff(value) {
    onChange({ ...task, cutoff: value });
  }

  function onSetSort(value) {
    onChange({
      ...task,
      sort: value,
      cutoff: value !== 'newest' ? null : cutoff,
      rating: value === 'rating' ? rating : null,
    });
  }

  return <Space size={16} className='w-100' direction='vertical'>
    <Space size={4} className='w-100' direction='vertical'>
      <Col xs={24} lg={6} xl={4}>
        <Form.Item label='Sorting'>
          <Space align='center' className='first-space-item-full'>
            <ReviewsSortSelect onChange={onSetSort} value={sort} options={reviewsSorts}/>
            <InfoTooltip title='Parameter specifies one of the sorting types.'/>
          </Space>
        </Form.Item>
      </Col>

      {sort === 'rating' &&
        <Col xs={24}>
          <p className='warning'>Sorting type &quot;rating&quot; works only for Apps</p>
        </Col>
      }
    </Space>

    {sort === 'newest' &&
        <Col xs={24} lg={6} xl={4}>
          <Form.Item label='Cutoff'>
            <Space direction='vertical' className='w-100'>
              <Checkbox checked={useCutoff} onChange={() => onSetUseCutoff()}>
              Stop at specific date (UTC)
              </Checkbox>
              {useCutoff &&
                <DatePicker
                  className='w-100'
                  showTime
                  format='YYYY/MM/DD HH:mm:ss'
                  value={cutoff}
                  onChange={onSetCutoff}
                  disabledDate={disabledDate}
                />
              }
            </Space>
          </Form.Item>
        </Col>
    }

    {sort === 'rating' &&
        <Col xs={24} lg={6} xl={4}>
          <Form.Item label='Rating'>
            <Space className='first-space-item-full'>
              <Select
                value={rating}
                optionFilterProp='children'
                onChange={(v) => onChange({ ...task, rating: v })}
              >
                <Option key='all' value={null}>All Ratings</Option>
                <Option key='1' value={1}>1-star</Option>
                <Option key='2' value={2}>2-star</Option>
                <Option key='3' value={3}>3-star</Option>
                <Option key='4' value={4}>4-star</Option>
                <Option key='5' value={5}>5-star</Option>
              </Select>
              <InfoTooltip title='Filter by a specific rating'/>

            </Space>
          </Form.Item>
        </Col>
    }
  </Space>;
}

ReviewsSortingFields.propTypes = {
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
