import { isEmpty } from 'lodash';
import { notification } from 'antd';
import config from 'react-global-configuration';

import { setPaidUser, setGoogleIntegrationExists } from '../../utils/utils';
import Mixpanel from '../../analytics/mixpanel';
import apiClient from '../../api/apiClient';
import apiProfile from '../../api/apiProfile';
import apiPayment from '../../api/apiPayment';

export const START_REQUEST = 'START_REQUEST';
export const STOP_REQUEST = 'STOP_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_BILLINGINFO_SUCCESS = 'FETCH_BILLINGINFO_SUCCESS';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';

const STRIPE_API_KEYS = config.get('stripeApiKeys');
const DEFAULT_REGION = 'main';

const apiDomainWithProtocol = `${window.location.protocol}//${config.get('apiDomain')}`;

export const startRequest = () => ({ type: START_REQUEST });
export const stopRequest = () => ({ type: STOP_REQUEST });

export const fetchProfile = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.get('/profile');
    const { account_status, integrations = {} } = data;
    const { google } = integrations;

    setPaidUser(account_status === 'valid');
    setGoogleIntegrationExists(!!google);

    dispatch({ profile: data, type: FETCH_PROFILE_SUCCESS });
    return data;
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
  return {};
};

export const updateProfile = (payload, callback = null) => async (dispatch, getState) => {
  dispatch(startRequest());
  try {
    const { data } = await apiProfile.updateProfile(payload);

    const { profile } = getState().profileReducer;
    dispatch({ profile: { ...profile, ...payload }, type: FETCH_PROFILE_SUCCESS });

    notification.success({ message: data });

    if (callback) callback();
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const fetchBillingInfo = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.get('/profile/billing_info');
    dispatch({ billingInfo: data, type: FETCH_BILLINGINFO_SUCCESS });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const updateBillingInfo = (payload) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.put('/profile/billing_info', payload);
    notification.success({ message: data });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Update billing info');
};

export const removeProfile = (navigate, redirect = '/logout') => async (dispatch) => {
  Mixpanel.track('Remove profile');

  dispatch(startRequest());
  try {
    const { data } = await apiClient.delete('/profile');

    notification.success({ message: data, duration: 6 });

    setTimeout(() => navigate(redirect), 5000);
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const inviteTenant = (email) => async (dispatch) => {
  Mixpanel.track('Invite tenant');

  dispatch(startRequest());
  try {
    const { data } = await apiProfile.inviteTenant(email);
    notification.success({ message: data });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const removeTenant = (tenantUserId) => async (dispatch) => {
  Mixpanel.track('Remove tenant');

  dispatch(startRequest());
  try {
    const { data } = await apiProfile.removeTenant(tenantUserId);

    notification.success({ message: data });

    dispatch(fetchProfile());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const stopSubscription = (subscription_id, cancel_at_period_end = false) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.post('/stop-customer-subscription', { subscription_id, cancel_at_period_end });

    notification.success({ message: data, description: 'The invoice will be finalized within a hour. You will receive it by email.', duration: 10 });
    dispatch(fetchProfile());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Stop subscription');
};

export const voidSubscription = (subscription_id) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.post('/void-customer-subscription', { subscription_id });

    notification.success({ message: data });
    dispatch(fetchProfile());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Clear subscription');
};

export const connectPaymentMethod = (secondaryTab = false, region = null) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.post('/create-checkout-session', { secondaryTab });
    const { sessionId } = data;

    var stripe = window.Stripe(STRIPE_API_KEYS[region || DEFAULT_REGION]);
    stripe.redirectToCheckout({ sessionId }).then((result) => {
      notification.error({ message: result.error.message });
    });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const makePayment = (deal, region = null, secondaryTab = false) => async (dispatch) => {
  try {
    dispatch(startRequest());

    const { data } = await apiClient.post('/create-payment-checkout-session', { deal, secondaryTab });
    const { sessionId } = data;
    var stripe = window.Stripe(STRIPE_API_KEYS[region || DEFAULT_REGION]);

    stripe.redirectToCheckout({ sessionId }).then((result) => {
      notification.error({ message: result.error.message });
    });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export async function nmiCharge(token, amount, onSuccess=null, onError=null) {
  try {
    const { data } = await apiPayment.nmiCharge(token, amount);
    const { response, responsetext } = data;

    if (response === '1') {
      notification.success({ message: 'Payment Successful' });
      if (onSuccess) onSuccess();
    } else {
      notification.error({ message: `Payment Unsuccessful: "${responsetext}"` });
      if (onError) onError();
    }
  } catch (error) {
    notification.error({ message: error.message });
    if (onError) onError();
  }
}

export const removePaymentMethod = () => async (dispatch) => {
  try {
    dispatch(startRequest());

    await apiClient.delete('/profile/payment_method');
    notification.success({ message: 'Removed' });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Remove payment method');
};

export const createAPIToken = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiProfile.createAPIToken();
    notification.success({ message: data });

    dispatch(fetchProfile());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Create API token');
};

export const revokeAPIToken = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiProfile.revokeAPIToken();

    notification.success({ message: data });
    dispatch(fetchProfile());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Revoke API token');
};

export const fetchIntegrations = (forceRelaod = false) => async (dispatch, getState) => {
  if (forceRelaod || isEmpty(getState().profileReducer.integrations)) {
    dispatch(startRequest());
    try {
      const { data } = await apiProfile.fetchIntegrations();
      dispatch({ integrations: data, type: FETCH_INTEGRATIONS_SUCCESS });
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      dispatch(stopRequest());
    }
  }
};

export const removeIntegration = (integration) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiProfile.removeIntegration(integration);

    if (integration === 'google') setGoogleIntegrationExists(false);

    notification.success({ message: data });
    dispatch(fetchIntegrations(true));
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const connectIntegration = (code, app, region, callback = null) => async (dispatch) => {
  let credentials = null;

  if (app === 'google') {
    credentials = await connectGoogleSheetsAndReturnCredentials();
    if (!credentials) return;
  }

  dispatch(startRequest());

  try {
    let data = null;

    if (app === 'hubspot' || !app) {
      ({ data } = await apiProfile.connectHubSpot(code));
    } else if (app === 'zoho') {
      ({ data } = await apiProfile.connectZoho(code, region));
    } else if (app === 'pipedrive') {
      ({ data } = await apiProfile.connectPipedrive(code));
    } else if (app === 'google' && credentials) {
      ({ data } = await apiProfile.connectGoogle(credentials));
      setGoogleIntegrationExists(true);
    } else {
      data = null;
    }

    if (data) notification.success({ message: data, duration: 8 });

    dispatch(fetchIntegrations(true));

    if (callback) callback();
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Connect integration', { app });
};

export async function connectGoogleSheetsAndReturnCredentials(callback = null) {
  return await new Promise((resolve, reject) => {
    const authWindow = window.open(`${apiDomainWithProtocol}/auth/oauth2-authorize`, '_blank', 'width=500,height=600');
    if (!authWindow) {
      reject('Popup blocked or failed to open');
      return;
    }

    const messageListener = (event) => {
      // if (event.origin !== apiDomainWithProtocol) return;
      const { access_token, refresh_token } = event.data;

      if (access_token && refresh_token) {
        setGoogleIntegrationExists(true);

        authWindow.close();
        window.removeEventListener('message', messageListener);
        const credentials = { access_token, refresh_token };

        if (callback) callback(credentials);
        resolve(credentials);
      } else {
        // TODO handle form closing?
        // authWindow.close();
        // window.removeEventListener('message', messageListener);
        // reject('Authorization failed');
      }
    };

    window.addEventListener('message', messageListener);
  });
}

export const updateGodMode = (email, callback = null) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiProfile.updateGodMode(email);
    notification.success({ message: data });
    if (callback) callback();
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const removeAndBlockUserByIP = (email) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiProfile.removeAndBlockUserByIP(email);
    notification.success({ message: data });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};
