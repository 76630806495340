import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Input, message, Popconfirm, Button, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';

import { createAPIToken, revokeAPIToken } from '../../redux/actions/profileActions';
import { warningMessage } from '../../shared/Components/PaymentInfo';
import { useMedia } from '../../hooks/useMedia';

export default function APIToken({ token, loading, disabled, accountNotValid, accountStatus }) {
  const isDisabled = (loading || disabled);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  function onClickFromInput() {
    if (token) {
      onCopy();
    } else {
      onGenerateAPIToken();
    }
  }

  function onGenerateAPIToken() {
    if (accountNotValid) {
      message.warning(accountStatus === 'open_invoices' ? <>
        <Trans i18nKey='title.closeInvoices'>Please close outstanding invoices before running new tasks</Trans>.
        <Button type='link' href='/invoices' target='_blank' rel='noopener noreferrer'><Trans i18nKey='action.viewInvoices'>View invoices</Trans></Button>
      </> : warningMessage, 5000);
    } else {
      dispatch(createAPIToken());
    }
  }

  function onRevokeAPIToken() {
    dispatch(revokeAPIToken());
  }

  function onCopy() {
    let apiToken = document.querySelector('.api-token');
    apiToken.select();
    document.execCommand('copy');

    message.success(t('description.copied', 'Copied to your clipboard'));
  }

  return <Space className={isDesktop ? 'w-100' : 'first-space-item-full'}>
    <Input
      readOnly
      className='api-token copieble w-100'
      value={token ? token : t('action.generateAPItoken', 'Click to generate API token')}
      disabled={isDisabled}
      onClick={onClickFromInput}
    />
    <Button
      className='noPadding'
      title={t('title.copyToClipboard', 'Copy to Clipboard')}
      icon={<FontAwesomeIcon icon={faCopy}/>}
      onClick={onCopy}
      disabled={isDisabled || !token}
    />
    <Popconfirm
      title={'Generate new API token?' + (token ? ' (previous token will be revoked)' : '')}
      okText={t('action.ok', 'Ok')}
      cancelText={t('action.cancel', 'Cancel')}
      onConfirm={onGenerateAPIToken}
      disabled={isDisabled}
    >
      <Button
        className='noPadding'
        title={t('action.update', 'Update')}
        icon={<FontAwesomeIcon icon={faSync}/>}
        disabled={isDisabled}
      />
    </Popconfirm>
    { token && (
      <Popconfirm
        title={t('title.areYouSure', 'Are you sure?')}
        okText={t('action.yes', 'Yes')}
        cancelText={t('action.no', 'No')}
        onConfirm={onRevokeAPIToken}
        disabled={isDisabled}
      >
        <Button
          className='noPadding'
          title={t('action.remove', 'Remove')}
          icon={<FontAwesomeIcon icon={faTrash}/>}
          disabled={isDisabled}
        />
      </Popconfirm>
    )}
  </Space>;
}

APIToken.propTypes = {
  token: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  accountNotValid: PropTypes.bool,
  accountStatus: PropTypes.string,
};
