import React, { useState } from 'react';
import queryString from 'query-string';
import { notification, Form, Button, Popconfirm, Select, InputNumber, Space, Card } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Testimonials from '../../../../shared/Components/Testimonials';
import Mixpanel from '../../../../analytics/mixpanel';
import apiPayment from '../../../../api/apiPayment';
import { useMedia } from '../../../../hooks/useMedia';

const { Option } = Select;
const { Meta } = Card;

const MIN_AMOUNT = 25;

export default function MakeCryptoPayment() {
  const location = useLocation();
  const navigate = useNavigate();
  const { amount: queryAmount } = queryString.parse(location.search);
  const [currency, setCurrency] = useState('btc');
  const [amount, setAmount] = useState(Math.max(Number(queryAmount) || 100, MIN_AMOUNT));
  const [loading, setLoading] = useState(false);
  const formatedCurrency = currency.toLocaleUpperCase();
  const bonus = 4;
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  function handleSetAmount(value) {
    setAmount(Number(value) || MIN_AMOUNT);
  }

  async function createCryptoPayment() {
    setLoading(true);
    try {
      const { data } = await apiPayment.createCryptoPayment(amount, currency);
      const { payment_id } = data;
      navigate(`/profile/crypto-payment/${payment_id}`);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }

    Mixpanel.track('Crypto payment intent', { currency });
  }

  return (
    <Space
      className='w-100 justifySpaceBetween'
      align={isDesktop ? 'start' : 'center'}
      size={40}
      direction={isDesktop ? 'horizontal' : 'vertical'}
    >
      <Card style={{ maxWidth: 450 }}>
        <Space direction='vertical' size={24}>
          <Meta
            title={t('title.addCredits', 'Add Credits')}
            description={<Trans i18nKey='description.addCreditsCrypto'>Deposit funds to your account balance with cryptocurrency and receive a {{ bonus }}% bonus from your payment.</Trans>}
          />
          <Form name='basic' layout='vertical'>
            <Space direction='vertical' size={16} className='w-100'>
              <Form.Item label={t('title.currency', 'Currency')}>
                <Select value={currency} onChange={setCurrency}>
                  <Option value='btc'>BTC</Option>
                  <Option value='eth'>ETH (Mainnet)</Option>
                  <Option value='usdtbsc'>USDT (BSC)</Option>
                  <Option value='usdttrc20'>USDT (TRC20)</Option>
                  <Option value='usdtmatic'>USDT (POLYGON)</Option>
                  <Option value='usdtsol'>USDT (SOL)</Option>
                  <Option value='usdc'>USDC (ETH)</Option>
                  <Option value='usdcbsc'>USDC (BSC)</Option>
                  <Option value='usdcmatic'>USDC (POLYGON)</Option>
                  <Option value='usdcsol'>USDC (SOL)</Option>
                  <Option value='xmr'>Monero (XMR)</Option>
                </Select>
              </Form.Item>
              <Form.Item label={t('title.amount', 'Amount') + ' USD'}>
                <InputNumber
                  className='w-100'
                  formatter={value => `$ ${value}`}
                  value={amount}
                  min={MIN_AMOUNT}
                  max={5000}
                  step={10}
                  onChange={handleSetAmount}
                />
              </Form.Item>
              <Form.Item>
                <Popconfirm
                  title={<Trans i18nKey='description.addCreditsConfirm'>Pay ${{ amount }} with {{ formatedCurrency }}?</Trans>}
                  okText={t('action.ok', 'Ok')}
                  cancelText={t('action.cancel', 'Cancel')}
                  onConfirm={createCryptoPayment}
                >
                  <Button className='w-100' loading={loading} title={`Create ${formatedCurrency} address and pay`} type='primary'>
                    <Trans i18nKey='action.addCreditsCrypto'>Create {{ formatedCurrency }} address and pay</Trans>
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Space>
          </Form>
        </Space>
      </Card>
      <Testimonials/>
    </Space>
  );
}
