import React from 'react';
import { Form, Select, InputNumber, Space, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from './Common/SimpleService';
import CustomItemsSelect from './Common/Selects/CustomItemsSelect';
import { services, defaultWebsiteEnrichments, defaultPhoneEnrichments } from '../../shared/data/services';

const { Option } = Select;
const REGION_DEFAULT_LOCATIONS = { US: 'New York, NY', AU: 'Sydney, NSW', CA: 'Toronto, ON' };
const DEFAULT_REGION = 'US';

export default function YellowPagesSearchScraper() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='business'
      title={services.yellowpages_service.name}
      subTitle={services.yellowpages_service.description}
      serviceName='yellowpages_service'
      queriesLabel={t('title.categories', 'Categories/brands')}
      queriesPlaceholder='bars&#10;restaurants&#10;dentists'
      learnMoreUrl='https://outscraper.com/yellowpages-scraper/'
      apiTag='/Others/paths/~1yellowpages-search/get'
      supportedEnrichmentServices={[]}
      defaultEnrichments={[ ...defaultWebsiteEnrichments, ...defaultPhoneEnrichments ]}
      allowFileUpload={false}
      limitType={null}
      defaultParams={{
        locations: REGION_DEFAULT_LOCATIONS[DEFAULT_REGION],
        region: DEFAULT_REGION,
        limit_per_query: 100,
      }}
      ExtraItems={({ updateTask, task }) => {
        return <Space className='w-100' size={16} direction='vertical'>
          <Col xs={24} lg={16} xl={12}>
            <Form.Item required label={t('title.locations', 'Locations')}>
              <CustomItemsSelect
                autoFocus
                value={task.locations}
                onChange={(v) => updateTask({ locations: v })}
                placeholder='New York, NY&#10;Los Angeles, CA'
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Form.Item required label={t('title.Region', 'Region')}>
              <Select value={task.region} onChange={(v) => updateTask({ region: v, locations: REGION_DEFAULT_LOCATIONS[v] })}>
                <Option key='US' value='US'>US (yellowpages.com)</Option>
                <Option key='AU' value='AU'>AU (yellowpages.com.au)</Option>
                <Option key='CA' value='CA'>CA (yellowpages.ca)</Option>
                <Option key='BE' value='BE'>BE (goldenpages.be)</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Form.Item label={t('title.perQueryLimit', 'Limit per one query')}>
              <InputNumber
                className='w-100'
                min={1}
                max={100000}
                step={100}
                value={task.limit_per_query}
                onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
              />
            </Form.Item>
          </Col>
        </Space>;
      }}
    />
  );
}
