import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { Trans } from 'react-i18next';

import { reviewsSorts } from '../../../../shared/data/sorts';

const { Option } = Select;

function ReviewsSortSelect({ value, onChange, options = reviewsSorts }) {
  return <>
    <Select
      showSearch
      value={value}
      optionFilterProp='children'
      onChange={onChange}
    >
      {options.map(r => <Option key={r.value} value={r.value}><Trans i18nKey={`title.${r.value}`}>{r.text}</Trans></Option>)}
    </Select>
  </>;
}

ReviewsSortSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
};

export default React.memo(ReviewsSortSelect, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
