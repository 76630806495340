import React from 'react';
import { Col, Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import SimpleService from '../Common/SimpleService';
import LanguageSelect from '../Common/Google/LanguageSelect';
import RegionSelect from '../Common/Google/RegionSelect';

export default function GoogleSearchShopping() {
  const { t } = useTranslation();
  const location = useLocation();

  const { hl: urlLanguage } = queryString.parse(location.search);

  return (
    <SimpleService
      title='Google Search Shopping Scraper'
      subTitle='Returns search results'
      serviceName='google_search_shopping_service'
      queriesPlaceholder='Iphone 13&#10;Dell monitor&#10;Gold eagle coin'
      apiTag='Google/paths/~1google-search-shopping/get'
      unitName='product'
      defaultParams={{
        limit_per_query: 100,
        region: 'US',
        language: urlLanguage ? urlLanguage : (localStorage.getItem('language') || 'en'),
      }}
      ExtraItems={({ updateTask, task }) => {
        const { region, language } = task;

        return (
          <Space size={16} className='w-100' direction='vertical'>
            <Col xs={24} lg={6} xl={4}>
              <Form.Item label={t('title.language', 'Language')}>
                <LanguageSelect value={language} onChange={(v) => updateTask({ region: v })} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={6} xl={4}>
              <Form.Item label={t('title.Region', 'Region')}>
                <RegionSelect allowClear={false} value={region} onChange={(v) => updateTask({ region: v })} />
              </Form.Item>
            </Col>
          </Space>
        );
      }}
    />
  );
}
