import React, { useEffect, useState } from 'react';
import { isEmpty, union } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Checkbox, Tooltip, Space, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import InfoTooltip from '../../../../shared/Components/InfoTooltip';
import CategoriesSelect from './CategoriesSelect';
import LocationsSelect from '../Selects/LocationsSelect';
import CustomItemsSelect from '../Selects/CustomItemsSelect';
import CustomQueries from '../Selects/CustomQueries';
import { getDefaultValue, setDefaultValue } from '../../../../utils/defaultProps';
import { isAdvancedUser } from '../../../../utils/localDB';
import plainQueriesSwitchFormHeader from './plainQueriesSwitchFormHeader';
import QueriesFormHeader from './QueriesFormHeader';
import { usePrevious } from '../../../../hooks/usePrevious';

const exactMatchFilter = { key: 'subtypes', operator: 'contains one of' };

const CUSTOM_CATEGORIES_KEY = 'customCategories';
const CUSTOM_LOCATIONS_KEY = 'customLocations';
const EXACT_MATCH_KEY = 'exactMatch';
const MAX_EXACT_MATCH_VALUES = isAdvancedUser ? 5000 : 100;

export function getDefaultIsCustomCategories() {
  return getDefaultValue(null, CUSTOM_CATEGORIES_KEY) === '1';
}

export function getDefaultIsCustomLocations() {
  return getDefaultValue(null, CUSTOM_LOCATIONS_KEY) === '1';
}

export default function QueriesFormItems({
  task, onUpdate, isCustomQueries, isCustomCategories, isCustomLocations,
  onIsCustomLocationsChange, extraHint, customQueriesKey, enrichmentFields, singleRegion, invalidFields
}) {
  const { queries, region, categories, customCategories, customLocations, exactMatch, input_file, filters = [], service_name = '', language, tags = [], settings } = task;

  const showExactMatch = !service_name.includes('reviews');
  const allowExactMatch = language === 'en' && categories.length <= MAX_EXACT_MATCH_VALUES && region !== 'GB';

  const prevLanguage = usePrevious(language);
  const [includesForeignCategory, setIncludesForeignCategory] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (language && prevLanguage && language !== 'en' && exactMatch) {
      handleOnExactMatchUpdate(false);
    }
  }, [language]);

  function onQueriesChange(queries) {
    onUpdate({ queries });
  }

  function getAutoTag(categories) {
    if (isEmpty(categories)) return null;

    let tag = `${categories[0]}`;
    if (categories.length > 1) tag += ` +${categories.length - 1}`;

    return tag;
  }

  function onCategoriesChange(newCategories, includesForeign) {
    setIncludesForeignCategory(includesForeign);
    const payload = { categories: newCategories };

    if (isEmpty(tags) || tags[0] === getAutoTag(categories)) {
      const autoTag = getAutoTag(newCategories);

      if (autoTag) {
        if (isEmpty(tags)) {
          payload.tags = [autoTag];
        } else {
          const newTags = [...tags];
          newTags[0] = autoTag;
          payload.tags = newTags;
        }
      } else {
        if (tags.length === 1) {
          payload.tags = [];
        } else {
          const newTags = [...tags];
          newTags.slice(1);
          payload.tags = newTags;
        }
      }
    }

    if (exactMatch) {
      const newFilters = [...filters];
      const filterIndex = filters.findIndex(f => isExactMatchFilter(f));

      if (filterIndex >= 0) {
        if (newCategories.length <= MAX_EXACT_MATCH_VALUES && !includesForeign) {
          const categoriesSet = new Set(categories);
          const newCategoriesSet = new Set(newCategories);

          const addedCategories = newCategories.filter(c => !categoriesSet.has(c));
          const removedCategories = new Set(categories.filter(c => !newCategoriesSet.has(c)));

          newFilters[filterIndex].value = [...newFilters[filterIndex].value.filter(v => !removedCategories.has(v)), ...addedCategories];
        } else {
          newFilters.splice(filterIndex, 1);
        }
      } else {
        if (newCategories.length <= MAX_EXACT_MATCH_VALUES && !includesForeign) {
          newFilters.push({ ...exactMatchFilter, value: newCategories });
        }
      }

      payload.filters = newFilters;
      payload.exactMatch = includesForeign ? false : exactMatch;
    }

    onUpdate(payload);
  }

  function onCustomCategoriesChange(customCategories) {
    onUpdate({ customCategories });
  }

  function onCustomLocationsChange(customLocations) {
    onUpdate({ customLocations });
  }

  function onFileUploaded(input_file, enrich, output_extension) {
    onUpdate({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
  }

  function handleOnIsCustomLocationsChange() {
    const value = !isCustomLocations;
    onIsCustomLocationsChange(value);
    setDefaultValue(value ? '1' : null, CUSTOM_LOCATIONS_KEY);
  }

  function handleOnExactMatchUpdate(newExactMatch) {
    if (newExactMatch) {
      if (isEmpty(filters)) {
        if (!isEmpty(categories)) {
          onUpdate({ exactMatch: true, filters: [{ ...exactMatchFilter, value: categories }] });
        } else {
          onUpdate({ exactMatch: true });
        }
      } else {
        const newFilters = [...filters];
        const filterIndex = filters.findIndex(f => isExactMatchFilter(f));

        if (filterIndex >= 0) {
          newFilters[filterIndex].value = union(newFilters[filterIndex].value, categories);
        } else {
          newFilters.push({ ...exactMatchFilter, value: categories });
        }

        onUpdate({ exactMatch: true, filters: newFilters });
      }
    } else {
      const newFilters = [...filters];
      const filterIndex = filters.findIndex(f => isExactMatchFilter(f));

      if (filterIndex >= 0) {
        const categoriesSet = new Set(categories);
        const newValue = newFilters[filterIndex].value.filter(v => !categoriesSet.has(v));
        if (isEmpty(newValue)) {
          newFilters.splice(filterIndex, 1);
        } else {
          newFilters[filterIndex].value = newValue;
        }
        onUpdate({ exactMatch: false, filters: newFilters });
      } else {
        onUpdate({ exactMatch: false });
      }
    }
  }

  function handleOnExactMatchChange() {
    if (isCustomCategories || isCustomQueries) return;

    const newValue = !exactMatch;

    handleOnExactMatchUpdate(newValue);
    setDefaultValue(newValue ? '1' : '0', EXACT_MATCH_KEY);
  }

  function isExactMatchFilter({ key, operator }) {
    return key === exactMatchFilter.key && operator === exactMatchFilter.operator;
  }

  const queriesLabel = (
    <QueriesFormHeader
      title={
        <Trans i18nKey='title.gm.queries'>Queries (category + city or zip + country), URLs, Google or Places IDs</Trans>
      }
      tooltipTitle={
        <Trans i18nKey='description.simpleQueries'>
          You can use anything that will work on Google Maps.
          {extraHint && <><br /><br />{extraHint}</>}
        </Trans>
      }
    >
      {plainQueriesSwitchFormHeader(task, onUpdate, customQueriesKey)}
    </QueriesFormHeader>
  );

  const categoriesSelectLabel = (
    <QueriesFormHeader
      title={
        <Trans i18nKey='title.categories'>Categories/brands</Trans>
      }
      tooltipTitle={
        <Trans i18nKey='description.categories'>
        Categories will be combined into Google search queries by multiplying with the
        locations below. You can use &quot;Plain queries&quot; to create the queries by your own
        as you would do it on Google Maps site.<br /><br />
        Sometimes Google adds other categories to your searches,
        please use <a
            href='https://outscraper.com/google-maps-data-scraper-filters/'
            target='_blank'
            rel='noopener noreferrer'
          >
        Filters
          </a> from Advanced Parameters to get only the exact categories.
          {extraHint && <><br /><br />{extraHint}</>}
        </Trans>
      }
    >
      {plainQueriesSwitchFormHeader(task, onUpdate, customQueriesKey)}
    </QueriesFormHeader>
  );

  return <Space size={16} className='w-100' direction='vertical'>
    {isCustomQueries ? (
      <Col xs={24} lg={16} xl={12}>
        <Form.Item required className='queries-input' label={queriesLabel}>
          <CustomQueries
            value={queries}
            onChange={onQueriesChange}
            inputFile={input_file}
            onFileUploaded={onFileUploaded}
            placeholder='Real estate agency, Rome, Italy&#10;ChIJu7bMNFV-54gR-lrHScvPRX4&#10;0x886916e8bc273979:0x5141fcb11460b226&#10;https://www.google.com/maps/search/restaurants+near+New+York,+NY/@40.7404216,-74.0229033,13z&#10;https://www.google.com/maps/place/?q=place_id:ChIJu7bMNFV-54gR-lrHScvPRX4'
            enrichmentFields={enrichmentFields}
            error={invalidFields.includes('queries')}
          />
        </Form.Item>
      </Col>
    ) : (
      <Space size={16} className='w-100' direction='vertical'>
        <Col xs={24} lg={16}>
          <Form.Item
            required
            className='categories-select'
            label={categoriesSelectLabel}
          >
            <Space size={4} className='w-100' direction='vertical'>
              {isCustomCategories ? (
                <CustomItemsSelect
                  placeholder='Auto Broker&#10;Italian restaurant&#10;Attorneys'
                  value={customCategories}
                  onChange={onCustomCategoriesChange}
                  error={invalidFields.includes('categories')}
                />
              ) : <CategoriesSelect onChange={onCategoriesChange} value={categories} maxExactMatchValues={MAX_EXACT_MATCH_VALUES} error={invalidFields.includes('categories')} /> }

              {isCustomCategories && <Form.Item className='inlineFormItem3'>
                <Trans i18nKey='description.customCategories'>Google might add other categories</Trans>, <a href='https://outscraper.com/google-maps-data-scraper-filters/' target='_blank' rel='noopener noreferrer'><Trans i18nKey='action.learnMore'>Learn more</Trans></a>
              </Form.Item>}

              {!isCustomCategories && showExactMatch && <Form.Item className='inlineFormItem3'>
                <Space align='center' className='nobreak'>
                  <Tooltip
                    title={allowExactMatch ? '' : <Trans i18nKey='description.customCategoriesDisabled'>Works only for EN language with up to {{ MAX_EXACT_MATCH_VALUES }} categories</Trans>}
                  >
                    <Checkbox
                      checked={exactMatch && allowExactMatch}
                      disabled={!allowExactMatch || includesForeignCategory}
                      onChange={handleOnExactMatchChange}
                    >
                      <span className='textWrap'>
                        <Trans i18nKey='title.exactMatch'>Exact match (ignore other categories Google might add)</Trans>
                      </span>
                    </Checkbox>
                  </Tooltip>
                  <InfoTooltip title={<Trans i18nKey='description.exactMatch'>
                  Sometimes Google might add other categories to your search results.
                    <br/><br/>
                  Parameter specifies whether to return only the categories you selected or everything that Google shows.
                    <br/><br/>
                  Works only for EN language and when using existing categories.
                    <br/><br/>
                  Please check <a href='https://outscraper.com/google-maps-data-scraper-filters/' target='_blank' rel='noopener noreferrer'>Filters</a> from advanced parameters to see how it works.
                  </Trans>}/>
                </Space>
              </Form.Item>}
            </Space>
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item
            required
            className='locations-select'
            label={<Space align='center'>
              <Trans i18nKey='title.locations'>Locations</Trans>
              {isCustomLocations && '(' + t('title.locationsExtra', 'city or zip + country') + ')'}
              <InfoTooltip
                title={<Trans i18nKey='description.locations'>
                Locations will be combined into Google search queries by multiplying with the
                categories above. You can use &quot;Plain queries&quot; to create the queries by
                your own as you would do it on Google Maps site.
                </Trans>}
              />
            </Space>}
          >
            <Space size={4} className='w-100' direction='vertical'>
              {isCustomLocations ? <>
                <CustomItemsSelect
                  placeholder='Brooklyn, NY, United States&#10;90001, Los Angeles, CA, USA&#10;near Bethesda Terrace, USA'
                  value={customLocations}
                  onChange={onCustomLocationsChange}
                  error={invalidFields.includes('customLocations')} />
              </> : <LocationsSelect task={task} onUpdate={onUpdate} singleRegion={singleRegion} error={invalidFields.includes('locations')} />}

              <Form.Item className='formItem2'>
                <Form.Item className='inlineFormItem3'>
                  <Checkbox
                    checked={isCustomLocations}
                    onChange={handleOnIsCustomLocationsChange}
                  ><Trans i18nKey='title.customLocations'>Custom locations</Trans></Checkbox>
                </Form.Item>
              </Form.Item>
            </Space>
          </Form.Item>
        </Col>
      </Space>
    )}
  </Space>;
}

QueriesFormItems.propTypes = {
  task: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isCustomQueries: PropTypes.bool.isRequired,
  isCustomCategories: PropTypes.bool.isRequired,
  isCustomLocations: PropTypes.bool.isRequired,
  onIsCustomCategoriesChange: PropTypes.func.isRequired,
  onIsCustomLocationsChange: PropTypes.func.isRequired,
  extraHint: PropTypes.string,
  customQueriesKey: PropTypes.string,
  enrichmentFields: PropTypes.array,
  singleRegion: PropTypes.string,
  invalidFields: PropTypes.array.isRequired
};
