import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, InputNumber, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import BaseService from '../Common/BaseService';
import CustomQueries from '../Common/Selects/CustomQueries';
import EnrichmentsItem from '../Common/EnrichmentsItem';
import UULEInput from '../Common/Google/UULEInput';
import LanguageSelect from '../Common/Google/LanguageSelect';
import RegionSelect from '../Common/Google/RegionSelect';
import InfoTooltip from '../../../shared/Components/InfoTooltip';
import GoogleDateRangeSelect from '../Common/Google/DateRangeSelect';
import GoogleLocationSelect from '../Common/Google/LocationSelect';

export default function GoogleSearchBase({ title = 'Google Search scraper', subTitle, serviceName = 'google_search_service',
  queriesLabel = 'Queries to search', queriesPlaceholder = '', isPerQueryLimit = null, apiTag,
  isGoogleLocationSelect = null, isGoogleRangeSelect = null, unitName = 'search', learnMoreUrl, videoTutorialLink, tutorialLink, maxLimitPagesPerQuery,
  showLanguageAndRegion = true, supportedEnrichmentServices, showUule = null,
}) {
  const location = useLocation();
  const { hl: urlLanguage } = queryString.parse(location.search);
  const localEnrichmentsKey = `${serviceName}_enrichments`;
  const taskExtraDefaultParams = {
    region: 'US',
    language: urlLanguage ? urlLanguage : (localStorage.getItem('language') || 'en'),
    enrichments: localStorage.getItem(localEnrichmentsKey) ? JSON.parse(localStorage.getItem(localEnrichmentsKey)) : [],
    uule: '',
    limit_per_query: isPerQueryLimit ? 100 : 1,
  };

  const { t } = useTranslation();
  const localeQueriesLabel = queriesLabel ? t(`title.${queriesLabel}`, queriesLabel) : '';

  return (
    <BaseService
      title={title}
      subTitle={subTitle}
      serviceName={serviceName}
      unitName={unitName}
      learnMoreUrl={learnMoreUrl}
      videoTutorialLink={videoTutorialLink}
      tutorialLink={tutorialLink}
      apiTag={apiTag}
      taskExtraDefaultParams={taskExtraDefaultParams}
      FormBody={({ task, updateTask, invalidFields }) => {
        const {
          queries,
          language,
          region,
          uule,
          tbs,
          limit_per_query,
          input_file: inputFile,
          settings
        } = task;

        function onSetLanguage(value) {
          updateTask({ language: value });
          localStorage.setItem('language', value);
        }

        function onEnrichmentChange(enrichments) {
          localStorage.setItem(localEnrichmentsKey, JSON.stringify(enrichments));
          updateTask({ enrichments });
        }

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        const labelConfig = isPerQueryLimit
          ? {
            label: t('title.perQueryLimit', 'Limit per one query'),
            tooltip: t('description.perQueryLimit', 'Parameter specifies the limit of results per one query'),
          }
          : {
            label: t('title.pagesPerQueryLimit', 'Pages limit per one query'),
            tooltip: t('description.pagesPerQueryLimit', 'Parameter specifies the limit of pages per one query'),
          };

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={12}>
            <Form.Item required label={localeQueriesLabel}>
              <CustomQueries
                value={queries}
                onChange={(v) => updateTask({ queries: v })}
                inputFile={inputFile}
                onFileUploaded={onFileUploaded}
                placeholder={queriesPlaceholder}
                error={invalidFields.includes('queries')}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label={labelConfig.label} wrapperCol={{ xs: { span: 24 }, lg: { span: 6 }, xl: { span:4 } }}>
              <Space align='center' className='first-space-item-full'>
                <InputNumber
                  className='w-100'
                  min={1}
                  max={isPerQueryLimit ? null : maxLimitPagesPerQuery}
                  step={isPerQueryLimit ? 100 : 1}
                  value={limit_per_query}
                  onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
                />
                <InfoTooltip title={labelConfig.tooltip} />
              </Space>
            </Form.Item>
          </Col>

          {isGoogleRangeSelect &&  <Col xs={24} lg={6} xl={4}>
            <Form.Item label={t('title.dateRange', 'Date range')}>
              <GoogleDateRangeSelect value={tbs} onChange={updateTask}/>
            </Form.Item>
          </Col>}

          {isGoogleLocationSelect ? (
            <Col xs={24} lg={6} xl={4}>
              <Form.Item label={t('title.country', 'Country')}>
                <GoogleLocationSelect value={region} onChange={updateTask}/>
              </Form.Item>
            </Col>
          ) : showLanguageAndRegion && (
            <>
              <Col xs={24} lg={6} xl={4}>
                <Form.Item label={t('title.language', 'Language')}>
                  <LanguageSelect onChange={onSetLanguage} value={language}/>
                </Form.Item>
              </Col>

              <Col xs={24} lg={6} xl={4}>
                <Form.Item label={t('title.Region', 'Region')}>
                  <RegionSelect allowClear={false} value={region} onChange={(v) => updateTask({ region: v })}/>
                </Form.Item>
              </Col>

              {showUule &&
                  <Col xs={24} lg={6} xl={4}>
                    <Form.Item label='UULE'>
                      <UULEInput allowClear={false} value={uule} onChange={(v) => updateTask({ uule: v })}/>
                    </Form.Item>
                  </Col>
              }
            </>
          )}

          {supportedEnrichmentServices &&
            <EnrichmentsItem
              task={task}
              updateTask={updateTask}
              onChange={onEnrichmentChange}
              supportedEnrichmentServices={supportedEnrichmentServices}
              title={title}
              learnMoreUrl={learnMoreUrl}
            />
          }
        </Space>;
      }}
    />
  );
}

GoogleSearchBase.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  serviceName: PropTypes.string,
  queriesLabel: PropTypes.string,
  queriesPlaceholder: PropTypes.string,
  isPerQueryLimit: PropTypes.bool,
  isGoogleLocationSelect: PropTypes.bool,
  isGoogleRangeSelect: PropTypes.bool,
  unitName: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  videoTutorialLink: PropTypes.string,
  tutorialLink: PropTypes.string,
  maxLimitPagesPerQuery: PropTypes.number,
  showLanguageAndRegion: PropTypes.bool,
  supportedEnrichmentServices: PropTypes.array,
  apiTag: PropTypes.string,
  showUule: PropTypes.bool,
};
