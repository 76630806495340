import React, { useState } from 'react';
import { Layout, Menu, Space, Badge, Drawer, Modal } from 'antd';
import {
  StarOutlined,
  StarFilled,
  AppstoreOutlined,
  AmazonOutlined,
  AppleFilled,
  FacebookOutlined,
  GoogleOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  EnvironmentOutlined,
  ShoppingOutlined,
  MailOutlined,
  PhoneOutlined,
  GlobalOutlined,
  ApiOutlined,
  ClockCircleOutlined,
  MenuOutlined,
  DollarOutlined,
  CreditCardOutlined,
  LogoutOutlined,
  UserOutlined,
  ScheduleOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  ReadOutlined,
  VideoCameraOutlined,
  TeamOutlined,
  CloseCircleOutlined,
  CustomerServiceOutlined,
  BugOutlined,
  FileTextOutlined,
  HistoryOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faChevronLeft, faLocationPin, faMagnet, faStar } from '@fortawesome/free-solid-svg-icons';
import { checkIfNewService } from '../../shared/data/services';
import { isEmpty } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import config from 'react-global-configuration';
import { isCredibleUser } from '../../utils/utils';
import { Logo, LogoMobile } from '../../assets/icons';
import './Header.scss';
import FeatureRequestModal from '../Others/FeatureRequestModal';
import { useMedia } from '../../hooks/useMedia';

const { Header } = Layout;
const notRoutes = new Set(['support', 'report', 'logout']);

const HeaderComponent = ({ auth }) => {
  const navigate = useNavigate();
  const { isDesktop } = useMedia();

  const { profile = {}, authenticated = false } = auth;
  const { avatar, name, email, roles = [] } = profile;
  const isTenant = roles.includes('tenant');
  const isAdmin = roles.includes('admin');

  const { t, i18n } = useTranslation();

  const [featureRequestOpen, setFeatureRequestOpen] = useState(false);
  const [isHelpOpened, setIsHelpOpened] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const getParsedLocalStorage = (key, defaultValue = []) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  };

  const starredServicesArray = getParsedLocalStorage('starredServices');
  const recentServicesArray = getParsedLocalStorage('recentServices');
  const recentNewServicesArray = [
    ...getParsedLocalStorage('recentNewServices'),
    window.location.pathname.split('/')[1],
  ];

  function showUserName(email) {
    if (email) {
      return email.split('@')[0];
    } else {
      return email;
    }
  }

  function onHelpClick() {
    if (isHelpOpened) {
      window.Chatra('closeChat');
    } else {
      window.Chatra('updateIntegrationData', { email });
      window.Chatra('openChat', true);
    }
    setIsHelpOpened(!isHelpOpened);
  }

  function handleLogout() {
    Modal.confirm({
      title: t('title.logOut', 'Log Out'),
      content: t('title.areYouSure', 'Are you sure?'),
      okText: t('action.yes', 'Yes'),
      okType: 'danger',
      cancelText: t('action.no', 'No'),
      onOk() {
        navigate('/logout');
      },
    });
  }

  function getBadgeCount(key, children) {
    if (checkIfNewService(key, recentNewServicesArray)) return 1;

    let result = 0;
    if (Array.isArray(children)) {
      children.forEach(({ key, children }) => {
        result += getBadgeCount(key ? key.split('?')[0] : key, children);
      });
    }

    return result;
  }

  function getItem(label, key, icon, children, type) {
    let translatedLabel = (icon || children || type) ? label : t(`title.${label}`, label);

    const formatedKey = key ? key.split('?')[0] : key;
    const badgeCount = getBadgeCount(formatedKey, children);
    const isNewService = checkIfNewService(formatedKey, recentNewServicesArray);

    if (isEmpty(children)) {
      if (key.startsWith('https://') || key.startsWith('http://')) {
        translatedLabel = <a href={key} target='_blank' rel='noopener noreferrer'>{translatedLabel}</a>;
      } else if (!notRoutes.has(key)) {
        const ln = i18n.language.split('-')[0];
        if (ln !== 'en') {
          key = key + (key.includes('?') ? '&' : '?') + `ln=${ln}`;
        }

        translatedLabel = <Link to={key.startsWith('/') ? key : `/${key}`}>{translatedLabel}</Link>;
      }
    }

    if (badgeCount || isNewService) {
      translatedLabel = <Badge
        title={isNewService ? 'New service' : `${badgeCount} new servic(es)`}
        size='small'
        dot={isNewService || null}
        count={isNewService ? null : badgeCount}
        offset={[isNewService ? 6 : badgeCount > 9 ? 20 : 12, 0]}
        overflowCount={9}
      >
        <span>{translatedLabel}&nbsp;</span>&nbsp;
      </Badge>;
    }

    if (type === 'group' && icon) {
      translatedLabel = <><span style={{ marginRight: 8 }}>{icon}</span>{translatedLabel}&nbsp;</>;
    }

    return {
      key,
      icon,
      children,
      items: children,
      label: translatedLabel,
      type,
      title: translatedLabel
    };
  }

  const profileMenu = [
    getItem(authenticated ? name || showUserName(email) : t('title.profile', 'Profile'), 'profile-items',  <Avatar
      className='profile-avatar'
      src={avatar}
      email={!avatar ? email : null}
      name={!avatar && !email ? name : showUserName(email)}
      size='32'
      round={true}
    />, [
      getItem(t('title.profile', 'Profile'), 'profile', <UserOutlined/>),
      ...(isTenant ? [] : [
        getItem(t('title.billingInformation', 'Billing Information'), 'billing-info', <CreditCardOutlined/>),
      ]),
      getItem(t('title.invoices', 'Invoices'), 'invoices', <FileTextOutlined />),
      getItem(t('title.logOut', 'Log Out'), 'logout', <LogoutOutlined/>)
    ])];

  const servicesMenu  = [
    getItem(t('title.byBrand', 'By Brand'), 'sources', null, [
      getItem('Amazon', 'source-amazon', <AmazonOutlined />, [
        getItem('Amazon Products Scraper', 'amazon-products?'),
        getItem('Amazon Reviews Scraper', 'amazon-reviews?'),
      ]),
      getItem('Apple', 'source-apple', <AppleFilled />, [
        getItem('AppStore Reviews Scraper', 'appstore-reviews?'),
      ]),
      getItem('Airbnb', 'source-airbnb', null, [
        getItem('Airbnb Search Scraper', 'airbnb-search?'),
        getItem('Airbnb Reviews Scraper', 'airbnb-reviews?'),
      ]),
      getItem('Booking', 'source-booking', null, [
        getItem('Booking Search Scraper', 'booking-search?'),
        getItem('Booking Reviews Scraper', 'booking-reviews?'),
        getItem('Booking Prices Scraper', 'booking-prices?'),
      ]),
      getItem('Reddit', 'source-reddit', null, [
        getItem('Reddit Search Scraper', 'reddit-search?'),
        getItem('Reddit Comments Scraper', 'reddit-comments?'),
      ]),
      getItem('Capterra', 'source-capterra', null, [
        getItem('Capterra Reviews Scraper', 'capterra-reviews?'),
      ]),
      getItem('eBay', 'source-ebay', null, [
        getItem('eBay Products Scraper', 'ebay-products?'),
      ]),
      getItem('Expedia', 'source-expedia', null, [
        getItem('Expedia Search Scraper', 'expedia-search?'),
        getItem('Expedia Reviews Scraper', 'expedia-reviews?'),
      ]),
      getItem('Facebook', 'source-facebook', <FacebookOutlined />, [
        getItem('Facebook Pages Scraper', 'facebook-pages?'),
        getItem('Facebook Reviews Scraper', 'facebook-reviews?'),
      ]),
      getItem('Fastbackgroundcheck', 'source-fastbackgroundcheck', null, [
        getItem('Fastbackgroundcheck Addresses Scraper', 'fastbackgroundcheck-addresses?'),
      ]),
      getItem('G2', 'source-g2', null, [
        getItem('G2 Reviews Scraper', 'g2-reviews?'),
      ]),
      getItem('GetApp', 'source-getapp', null, [
        getItem('GetApp Reviews Scraper', 'getapp-reviews?'),
      ]),
      getItem('Google', 'source-google', <GoogleOutlined />, [
        getItem('Google Maps Data Scraper', 'googleMaps?'),
        getItem('Google Maps Reviews Scraper', 'googleReviews?'),
        getItem('Google Search Scraper', 'google-search?'),
        getItem('Google Maps Search by Domains', 'google-places-domain-search?'),
        getItem('Google Maps Contributor Reviews Scraper', 'google-maps-contributor-reviews?'),
        getItem('Google Maps Photos Scraper', 'google-maps-photos?'),
        getItem('Google Maps Traffic Scraper', 'googleTraffic?'),
        getItem('Google Maps Directory Places', 'google-directory-places?'),
        getItem('Google Search News Scraper', 'google-search-news?'),
        getItem('Google Search Images Scraper', 'google-search-images?'),
        getItem('Google Search Videos Scraper', 'google-search-videos?'),
        getItem('Google Search Events Scraper', 'google-search-events?'),
        getItem('Google Search Jobs', 'google-search-jobs?'),
        getItem('Google Search Careers', 'google-careers?'),
        getItem('Google Search Shopping Scraper', 'google-search-shopping?'),
        getItem('Google Trends Scraper', 'google-trends?'),
        getItem('Google Play Reviews Scraper', 'google-play-reviews?'),
        getItem('Google Shopping Reviews Scraper', 'google-shopping-reviews?'),
      ]),
      getItem('Glassdoor', 'source-glassdoor', null, [
        getItem('Glassdoor Job Scraper', 'glassdoor-search?'),
        getItem('Glassdoor Reviews Scraper', 'glassdoor-reviews?'),
      ]),
      getItem('Home Depot', 'source-homedepot', null, [
        getItem('Home Depot Reviews Scraper', 'homedepot-reviews?'),
      ]),
      getItem('Hotels', 'source-hotels', null, [
        getItem('Hotels Search Scraper', 'hotels-search?'),
        getItem('Hotels Reviews Scraper', 'hotels-reviews?'),
      ]),
      getItem('Indeed', 'source-indeed', null, [
        getItem('Indeed Job Scraper', 'indeed-search?'),
        getItem('Indeed Reviews Scraper', 'indeed-reviews?'),
      ]),
      getItem('Instagram', 'source-instagram', <InstagramOutlined />, [
        getItem('Instagram Profiles Scraper', 'instagram-profiles?'),
      ]),
      getItem('Linkedin', 'source-Linkedin', <LinkedinOutlined />, [
        getItem('Linkedin Companies Scraper', 'linkedin-companies?'),
        getItem('Linkedin Profiles Scraper', 'linkedin-profiles?'),
        getItem('Linkedin Posts Scraper', 'linkedin-posts?'),
      ]),
      getItem('Product Hunt', 'source-producthunt', null, [
        getItem('Product Hunt Reviews Scraper', 'producthunt-reviews?'),
      ]),
      getItem('Shopee', 'source-shopee', null, [
        getItem('Shopee Reviews Scraper', 'shopee-reviews?'),
      ]),
      getItem('Shopify', 'source-shopify', null, [
        getItem('Shopify Products Scraper', 'shopify-products?'),
      ]),
      getItem('Target', 'source-target', null, [
        getItem('Target Reviews Scraper', 'target-reviews?'),
      ]),
      getItem('TikTok', 'source-tiktok', null, [
        getItem('TikTok Profiles Scraper', 'tiktok-profiles?'),
      ]),
      getItem('Tripadvisor', 'source-tripadvisor', <FontAwesomeIcon icon={faBuilding}/>, [
        getItem('Tripadvisor Search Scraper', 'tripadvisor-search?'),
        getItem('Tripadvisor Reviews Scraper', 'tripadvisor-reviews?'),
      ]),
      getItem('Trustpilot', 'source-trustpilot', <FontAwesomeIcon icon={faStar}/>, [
        getItem('Trustpilot Scraper', 'trustpilot?'),
        getItem('Trustpilot Search Scraper', 'trustpilot-search?'),
        getItem('Trustpilot Reviews Scraper', 'trustpilot-reviews?'),
      ]),
      getItem('Twitter', 'source-twitter', <TwitterOutlined />, [
        getItem('Twitter Profiles Scraper', 'twitter-profiles?'),
      ]),
      getItem('Walmart', 'source-walmart', null, [
        getItem('Walmart Reviews Scraper', 'walmart-reviews?'),
      ]),
      getItem('Whitepages', 'source-whitepages', null, [
        getItem('Phone Identity Finder', 'whitepages-phones?'),
        getItem('Whitepages Addresses Scraper', 'whitepages-addresses?'),
      ]),
      getItem('Youtube', 'source-youtube', <YoutubeOutlined />, [
        getItem('YouTube Channels Scraper', 'youtube-channels?'),
        getItem('YouTube Comments Scraper', 'youtube-comments?'),
        getItem('YouTube Search Scraper', 'youtube-search?'),
      ]),
      getItem('Yellow Pages', 'yellow-pages', null, [
        getItem('Yellow Pages Search Scraper', 'yellowpages?'),
      ]),
      getItem('Y.E.L.P', 'source-yelp', <FontAwesomeIcon icon={faBuilding} />, [
        getItem('Y.E.L.P Businesses Scraper', 'yelp?'),
        getItem('Y.E.L.P Reviews Scraper', 'yelp-reviews?'),
        getItem('Y.E.L.P Photos Scraper', 'yelp-photos?'),
      ]),
      getItem('Zillow', 'source-zillow', <FontAwesomeIcon icon={faBuilding} />, [
        getItem('Zillow Search Scraper', 'zillow-search?'),
      ]),
    ]),
    getItem(t('title.byType', 'By Type'), 'types', null, [
      getItem(t('title.poiRelated', 'Businesses & POI'), 'pois', <EnvironmentOutlined />, [
        getItem('Google Maps Data Scraper', 'googleMaps?tab=businesses'),
        getItem('Google Maps Search by Domains', 'google-places-domain-search'),
        getItem('Facebook Pages Scraper', 'facebook-pages'),
        getItem('Tripadvisor Search Scraper', 'tripadvisor-search'),
        getItem('Trustpilot Search Scraper', 'trustpilot-search'),
        getItem('Trustpilot Scraper', 'trustpilot'),
        getItem('Linkedin Companies Scraper', 'linkedin-companies'),
        getItem('Whitepages Addresses Scraper', 'whitepages-addresses'),
        getItem('Fastbackgroundcheck Addresses Scraper', 'fastbackgroundcheck-addresses'),
        getItem('Yellow Pages Search Scraper', 'yellowpages'),
        getItem('Y.E.L.P Businesses Scraper', 'yelp'),
        getItem('Company Insights', 'company-insights'),
        getItem('Business Directory', 'https://targetron.com/b2b-local-leads-directory/'),
        getItem('Hotels', 'hotels', null, [
          getItem('Airbnb Search Scraper', 'airbnb-search'),
          getItem('Booking Search Scraper', 'booking-search'),
          getItem('Booking Prices Scraper', 'booking-prices'),
          getItem('Expedia Search Scraper', 'expedia-search'),
          getItem('Hotels Search Scraper', 'hotels-search'),
        ]),
      ]),
      getItem(t('title.reviewsRelated', 'Reviews & Comments'), 'reviews', <StarOutlined />, [
        getItem('Amazon Reviews Scraper', 'amazon-reviews'),
        getItem('AppStore Reviews Scraper', 'appstore-reviews'),
        getItem('Airbnb Reviews Scraper', 'airbnb-reviews'),
        getItem('Booking Reviews Scraper', 'booking-reviews'),
        getItem('Capterra Reviews Scraper', 'capterra-reviews'),
        getItem('Expedia Reviews Scraper', 'expedia-reviews'),
        getItem('Facebook Reviews Scraper', 'facebook-reviews'),
        getItem('G2 Reviews Scraper', 'g2-reviews'),
        getItem('GetApp Reviews Scraper', 'getapp-reviews'),
        getItem('Glassdoor Reviews Scraper', 'glassdoor-reviews'),
        getItem('Google Maps Reviews Scraper', 'googleReviews'),
        getItem('Google Maps Contributor Reviews Scraper', 'google-maps-contributor-reviews'),
        getItem('Google Play Reviews Scraper', 'google-play-reviews'),
        getItem('Google Shopping Reviews Scraper', 'google-shopping-reviews'),
        getItem('Home Depot Reviews Scraper', 'homedepot-reviews'),
        getItem('Hotels Reviews Scraper', 'hotels-reviews'),
        getItem('Indeed Reviews Scraper', 'indeed-reviews'),
        getItem('Product Hunt Reviews Scraper', 'producthunt-reviews'),
        getItem('Reddit Comments Scraper', 'reddit-comments'),
        getItem('Shopee Reviews Scraper', 'shopee-reviews'),
        getItem('Target Reviews Scraper', 'target-reviews'),
        getItem('Tripadvisor Reviews Scraper', 'tripadvisor-reviews'),
        getItem('Trustpilot Reviews Scraper', 'trustpilot-reviews'),
        getItem('Walmart Reviews Scraper', 'walmart-reviews'),
        getItem('YouTube Comments Scraper', 'youtube-comments'),
        getItem('Y.E.L.P Reviews Scraper', 'yelp-reviews'),
      ]),
      getItem(t('title.productsRelated', 'Products & Listings'), 'products', <ShoppingOutlined />, [
        getItem('Amazon Products Scraper', 'amazon-products'),
        getItem('eBay Products Scraper', 'ebay-products'),
        getItem('Google Search Shopping Scraper', 'google-search-shopping'),
        getItem('Shopify Products Scraper', 'shopify-products'),
      ]),
      getItem(t('title.emailRelated', 'Email Related'), 'emails', <MailOutlined />, [
        getItem('Emails & Contacts Scraper', 'emails-scraper'),
        getItem('Contacts Finder', 'contacts-finder'),
        getItem(t('action.check', 'Check'), null, null, [
          getItem('Email Address Verifier', 'emails-validator'),
          getItem('Disposable Email Checker', 'disposable-email-checker'),
        ], 'group'),
        getItem('Email Addresses Finder', 'email-finder'),
      ]),
      getItem(t('title.phoneRelated', 'Phone Related'), 'phones', <PhoneOutlined />, [
        getItem('Phone Numbers Enricher', 'phones-enricher'),
        getItem('Phone Identity Finder', 'whitepages-phones'),
        getItem(t('action.check', 'Check'), null, null, [
          getItem('WhatsApp Checker', 'whatsapp-checker'),
          // getItem('iMessage Checker', 'imessage-checker'),
          // getItem('Telegram Checker', 'telegram-checker'),
          // getItem('WeChat Checker', 'wechat-checker'),
        ], 'group'),
        // getItem(t('action.send', 'Send'), null, null, [
        //   getItem('WhatsApp Sender', 'whatsapp-sender'),
        //   getItem('iMessage Sender', 'imessage-sender'),
        // ], 'group'),
      ]),
      getItem(t('title.domainRelated', 'Domain Related'), 'domains', <GlobalOutlined />, [
        getItem('Emails & Contacts Scraper', 'emails-scraper?tab=domain'),
        getItem('Contacts Finder', 'contacts-finder?tab=domain'),
        getItem('Company Insights', 'company-insights?tab=domain'),
        getItem('Google Maps Search by Domains', 'google-places-domain-search?tab=domain'),
        getItem('Trustpilot Scraper', 'trustpilot?type=domain'),
      ]),
    ]),
    getItem(t('title.byUseCase', 'Use Cases'), 'usecases', null, [
      getItem(t('title.leadGeneration', 'Lead Generation'), 'usecases-lead', <FontAwesomeIcon icon={faMagnet}/>, [
        getItem('Lead Scraper', 'googleMaps?tab=lead'),
        getItem('Contacts Finder', 'contacts-finder?tab=lead'),
        getItem('Emails & Contacts Scraper', 'emails-scraper?tab=lead'),
        getItem('Email Addresses Finder', 'email-finder?tab=lead'),
        getItem('Email Address Verifier', 'emails-validator?tab=lead'),
        getItem('Disposable Email Checker', 'disposable-email-checker?tab=lead'),
      ]),
      getItem(t('title.reputationManagement', 'Reputation Management'), 'usecases-rep', <StarFilled/>, [
        getItem('Amazon Reviews Scraper', 'amazon-reviews?tab=rep'),
        getItem('AppStore Reviews Scraper', 'appstore-reviews?tab=rep'),
        getItem('Booking Reviews Scraper', 'booking-reviews?tab=rep'),
        getItem('Capterra Reviews Scraper', 'capterra-reviews?tab=rep'),
        getItem('Facebook Reviews Scraper', 'facebook-reviews?tab=rep'),
        getItem('Google Maps Reviews Scraper', 'googleReviews?tab=rep'),
        getItem('Google Maps Contributor Reviews Scraper', 'google-maps-contributor-reviews?tab=rep'),
        getItem('Google Play Reviews Scraper', 'google-play-reviews?tab=rep'),
        getItem('Google Shopping Reviews Scraper', 'google-shopping-reviews?tab=rep'),
        getItem('Hotels Reviews Scraper', 'hotels-reviews?tab=rep'),
        getItem('Product Hunt Reviews Scraper', 'producthunt-reviews?tab=rep'),
        getItem('Shopee Reviews Scraper', 'shopee-reviews?tab=rep'),
        getItem('Tripadvisor Reviews Scraper', 'tripadvisor-reviews?tab=rep'),
        getItem('Trustpilot Reviews Scraper', 'trustpilot-reviews?tab=rep'),
        getItem('YouTube Comments Scraper', 'youtube-comments?tab=rep'),
        getItem('Y.E.L.P Reviews Scraper', 'yelp-reviews?tab=rep'),
      ]),
      getItem(t('title.locationIntelligence', 'Location Intelligence'), 'usecases-intel', <FontAwesomeIcon icon={faLocationPin}/>, [
        getItem('Google Maps Data Scraper', 'googleMaps?tab=intel'),
        getItem('Google Maps Search by Domains', 'google-places-domain-search?tab=intel'),
        getItem('Y.E.L.P Businesses Scraper', 'yelp?tab=intel'),
        getItem('Yellow Pages Search Scraper', 'yellowpages?tab=intel'),
      ]),
    ]),
    getItem(t('title.otherServices', 'Other Services'), 'others', null, [
      getItem('Universal AI-Powered Scraper', 'universal-scraper'),
      getItem('WebPage Screenshoter', 'screenshoter'),
      getItem('HubSpot', null, null, [
        getItem('HubSpot Contact Emails Finder', 'hubspot-contact-emails-finder'),
        getItem('HubSpot Company Contacts Finder', 'hubspot-company-contacts-finder'),
      ], 'group'),
      getItem('Geocoding', null, null, [
        getItem('Geocoding', 'geocoding'),
        getItem('Reverse Geocoding', 'reverse-geocoding'),
      ], 'group'),
    ]),
    ...(isAdmin ? [
      getItem('Internal Services', 'internal', null, [
        getItem('Places DB Search', 'places-search'),
        getItem('Places DB Update', 'places-update'),
        getItem('Domain Contacts Finder', 'domain-contacts-finder'),
      ])
    ] : []),
  ];
  const favoritesAndRecentServices = [
    !isEmpty(recentServicesArray) && getItem(t('title.recentlyVisited', 'Recently Visited'), 'recently-visited', <ClockCircleOutlined />, [
      ...recentServicesArray.map((service) => {
        const { title, href } = service;
        return getItem(title, href + '?tab=recent');
      }),
    ]),
    !isEmpty(starredServicesArray) && getItem(t('title.favorites', 'Favorites'), 'services.favorites', <StarOutlined />,
      [    ...starredServicesArray.map((service) => {
        const { title, href } = service;
        return getItem(title, href);
      }),
      ]),
  ];
  const apiMenu = [
    getItem(t('title.apiDocs', 'API Docs'), 'api-docs', <FileTextOutlined/>),
    getItem(t('title.apiUsage', 'API Usage History'), 'api-usage', <HistoryOutlined/>),
    getItem(t('title.integrations', 'Integrations'), 'integrations', <LinkOutlined/>),
  ];
  const helpMenu = [
    getItem('F.A.Q.', config.get('faqUrl'), <QuestionOutlined />),
    getItem(t('title.tutorials', 'Tutorials'), config.get('tutorialsUrl'), <ReadOutlined />),
    getItem(t('title.videos', 'Videos'), config.get('videosUrl'), <VideoCameraOutlined />),
    getItem(t('title.askCommunity', 'Ask Community'), config.get('communityUrl'), <TeamOutlined/>),
    getItem(t('title.support', 'Support'), 'support', isHelpOpened ? <CloseCircleOutlined /> : <CustomerServiceOutlined />),
    ...(isCredibleUser() ? [getItem(t('title.schedule', 'Schedule a Call'), 'https://outscraper.com/support/#schedule', <PhoneOutlined />)] : []),
    getItem(t('title.reportProblem', 'Report a Problem'), 'report', <BugOutlined />),
    getItem(t('title.pricing', 'Pricing'), config.get('pricingUrl'), <DollarOutlined/>),
  ];
  const rootMenu = [
    getItem(t('title.services', 'Services'), 'services', <AppstoreOutlined />, servicesMenu),
    getItem(t('title.tasks', 'Tasks'), 'tasks', <ScheduleOutlined />),
    getItem('API' + ' & ' + t('title.integrations', 'Integrations'), 'api-integrations', <ApiOutlined />, apiMenu),
    getItem(t('title.help', 'Help'), 'help', <QuestionCircleOutlined />, helpMenu)
  ];

  const [menuStack, setMenuStack] = useState([getItem('Main Menu', 'root', null, rootMenu)]);
  const currentMenu = menuStack[menuStack.length - 1];

  const handleHeaderMenuClick = (menuKey) => {
    const selectedMenu = rootMenu.find((item) => item.key === menuKey);
    if (selectedMenu?.items?.length > 0) {
      setMenuStack([getItem('Main Menu', 'root', null, rootMenu), selectedMenu]);
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  };

  const goBack = () => {
    setMenuStack((prev) => {
      if (prev.length > 1) {
        return prev.slice(0, -1);
      } else {
        return [getItem('Main Menu', 'root', null, rootMenu)];
      }
    });
  };

  const handleMenuClick = ({ key }) => {
    const selectedItem = currentMenu.items.find((item) => item.key === key);

    if (selectedItem?.items?.length > 0) {
      setMenuStack((prev) => [...prev, selectedItem]);
    } else {
      if (key.startsWith('https://') || key.startsWith('http://')) {
        window.open(key, '_blank', 'noopener,noreferrer');
      } else if (key === 'support') {
        onHelpClick();
      } else if (key === 'report') {
        setFeatureRequestOpen((prev) => !prev);
      } else if (key === 'logout') {
        handleLogout();
      } else {
        navigate(key);
      }
      setDrawerOpen(false);
    }
  };

  const drawerTitle = menuStack.length > 1 ?
    <Space align='center' className='drawer-title' onClick={goBack}>
      <span className='back-icon'><FontAwesomeIcon icon={faChevronLeft}/></span>
      <h4 className='h4 bold'>{currentMenu.label}</h4>
    </Space> : <h4 className='h4 bold'>Menu</h4>;

  const accountMenu = () =>
    <Menu className='account-menu' theme={isDesktop ? 'dark' : 'light'} mode={isDesktop ? 'horizontal' : 'inline'} items={profileMenu} onClick={handleMenuClick} />;

  return (
    <Header theme='light' className='header'>
      <Link to='/' className='logo'>
        {isDesktop ? <Logo /> : <LogoMobile />}
      </Link>
      {isDesktop ?
        <Space size={32} className='menu'>
          {rootMenu.map((item) => (
            <span className='menu-item' key={item.key} onClick={() => handleHeaderMenuClick(item.key)}>
              {item.title}
            </span>
          ))}
        </Space> :
        <MenuOutlined className='burger-menu' onClick={() =>  setDrawerOpen(true)} />
      }
      <Drawer
        title={drawerTitle}
        placement={isDesktop ? 'left' : 'right'}
        onClose={() => setDrawerOpen(false)}
        open={isDrawerOpen}
      >
        {!isDesktop && (currentMenu.key === 'root') && accountMenu()}
        <Menu
          className='main-menu'
          mode='inline'
          openKeys={[]}
          items={currentMenu.items.map((item) => ({
            key: item.key,
            icon: item.icon,
            label: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleMenuClick({ key: item.key });
                }}
              >
                {item.title}
              </div>
            ),
            children: item.children,
          }))}
        />
        {currentMenu.key === 'services' &&
            <Menu
              className='favorites-recent-menu'
              defaultOpenKeys={['recently-visited']}
              mode='inline'
              items={favoritesAndRecentServices}
              onClick={handleMenuClick}
            />}
      </Drawer>
      {isDesktop && accountMenu()}
      <FeatureRequestModal bug open={featureRequestOpen} onChange={setFeatureRequestOpen}/>
    </Header>
  );
};

HeaderComponent.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default HeaderComponent;
