import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Select, message, notification, Space, Button, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import config from 'react-global-configuration';
import { useLocation, useNavigate } from 'react-router-dom';

import { setCredibleUser } from '../../utils/utils';
import { fetchProfile, updateProfile, removeProfile,
  connectPaymentMethod, makePayment
} from '../../redux/actions/profileActions';
import Href from '../../shared/Components/Href';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import { appLanguages } from '../../shared/data/languages';
import APIToken from './APIToken';
import ReferralProgram from './ReferralProgram';
import UpcomingInvoice from './UpcomingInvoice';
import ProfileDangerousActions from './ProfileDangerousActions';
import PromotionCode from './PromotionCode';
import SupportBlock from './SupportBlock';
import NotificationsSettings from './NotificationsSettings';
import EmailAddress from './EmailAddress';
import Tenants from './Tenants';
import Balance from './Balance';
import { useMedia } from '../../hooks/useMedia';

const { Option } = Select;

export default function Profile({ authForwarded }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isDesktop } = useMedia();

  const { search = {} } = location;
  const { profile: authProfile } = authForwarded || {};
  const { emailVerified, email, roles, provider, region, userId, ie } = authProfile || {};

  const isSupport = roles.includes('support') || roles.includes('sales');
  const isTenant = roles.includes('tenant');
  const { session_id, checkout, secondary_tab, deposit, deposited } = queryString.parse(search);
  const isSecondaryTab = secondary_tab && secondary_tab === 'true';

  const profile = useSelector(state => state.profileReducer.profile);
  const loading = useSelector(state => state.profileReducer.loading);
  const allowCr = email ? !(email.endsWith('ov') || email.endsWith('du')) : false;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [passwordChangeRequested, setPasswordChangeRequested] = useState(false);

  const { account_status, api_token, risk_level, notifications = {}, upcoming_invoice = {},
    coupon = {}, tenants = [], referred, balance, referral_percentage_return: REFERRAL_PERCENTAGE_RETURN = 10,
  } = profile;
  const { name: couponName } = coupon;
  const accountNotValid = account_status !== 'valid';

  if (balance && balance > 0) setCredibleUser();

  useEffect(() => {
    document.title = t('title.profile', 'Profile') + ' | ' + config.get('company');

    if (checkout === 'success' && session_id) {
      setCredibleUser();
      notification.success({ message: t('message.paymentMethodConnected', 'Payment method is successfully connected') });
      onFetchProfile();
    } else if (checkout === 'true') {
      dispatch(connectPaymentMethod(false, region));
    } else if (deposit) {
      handleOnMakePayment(deposit, isSecondaryTab);
    } else if (deposited === 'true') {
      setCredibleUser();
      onFetchProfile();
      if (isSecondaryTab) {
        message.success(t('message.previousTab', 'Return to the previous tab to start your task'), 30);
        setTimeout(() => window.close(), 1000);
      }
    }
    else {
      onFetchProfile();
    }
  }, []);

  async function onPaymentMade() {
    await onFetchProfile();
    if (isSecondaryTab) {
      setTimeout(() => window.close(), 1000);
    }
  }

  function handleOnMakePayment(deal, secondaryTab) {
    onMakePayment(deal, region, secondaryTab);
  }

  function onUpdateProfile(payload) {
    dispatch(updateProfile(payload));
  }

  function onConnectPaymentMethod() {
    dispatch(connectPaymentMethod(false, region));
  }

  function onMakePayment(deal, region) {
    dispatch(makePayment(deal, region, isSecondaryTab));
  }

  function onFetchProfile() {
    return dispatch(fetchProfile());
  }

  async function onPasswordChangeRequest() {
    setPasswordChangeRequested(true);
    window.location = `/reset-password/${localStorage.getItem('accessToken')}`;
  }

  function onProfileRemove() {
    dispatch(removeProfile(navigate, ie ? '/profile' : '/logout'));
  }

  function onEmailChanged(newEmail) {
    authForwarded.logout(newEmail);
  }

  function changeLanguage(value) {
    i18n.changeLanguage(value);
    window.location.reload();
  }

  return <Space direction='vertical' size={32} className='w-100'>
    <Balance loading={loading} profile={profile} onMakePayment={onMakePayment} onPaymentMade={onPaymentMade} region={region} crypto={allowCr} isTenant={isTenant} emailVerified={emailVerified} />

    <Space direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'><Trans i18nKey='title.apiToken'>API Token</Trans></h3>
      <Space direction='vertical' className='w-100'>
        <p className='main-text'>
          <Trans i18nKey='description.useAPI'>Use services from your code</Trans><Trans i18nKey='dot'>.</Trans>
          <Href external href='/api-docs'> <Trans i18nKey='title.apiDocs'>API Docs</Trans></Href>
        </p>
        <APIToken loading={loading} token={api_token} accountNotValid={accountNotValid} accountStatus={account_status}/>
        {account_status === 'no_default_source' && emailVerified && risk_level < 2 &&
        <Button
          color='danger'
          variant='link'
          className='noPadding'
          onClick={onConnectPaymentMethod}
        >*<Trans i18nKey='message.addCredit'>Please add credits to your account balance or add billing information before using the API</Trans>.
        </Button>
        }
      </Space>
    </Space>

    {!isTenant && <Space direction='vertical' size={32} className='w-100'>
      <Space direction='vertical' size={16} className='w-100'>
        <h3 className='h3 bold'><Trans i18nKey='title.referral'>Referral Program</Trans></h3>
        <Space direction='vertical' className='w-100'>
          <p className='main-text'>
            <Trans i18nKey='description.referral'>
            Refer a friend and start receiving <strong>{{ REFERRAL_PERCENTAGE_RETURN }}%</strong> of your
            referral payments to your account balance.
            </Trans>
          </p>
          <Space align={isDesktop ? 'center' : 'baseline'} className='first-space-item-full'>
            <ReferralProgram userId={userId} referred={referred}/>
          </Space>
        </Space>
      </Space>

      <Space className='w-100' direction='vertical' size={16}>
        <h3 className='h3 bold'><Trans i18nKey='title.usage'>Upcoming Invoice</Trans></h3>
        <UpcomingInvoice loading={loading} value={upcoming_invoice} couponName={couponName} isSupport={isSupport}/>
      </Space>

      <Space direction='vertical' size={16} className='w-100'>
        <h3 className='h3 bold'><Trans i18nKey='title.promo'>Promotion Code</Trans></h3>
        <PromotionCode existingCouponName={couponName}/>
      </Space>

      <Space size={4} direction='vertical'>
        <p className='tag'>
          * <Trans i18nKey='description.usageThreshold'>Metered usage will start billing immediately if it reaches a
          certain threshold</Trans>.
        </p>
        <p className='tag'>
          * <Trans i18nKey='description.invocie'>A new billing period will be generated when a task is performed without
          an existing billing period in effect.
          Once you have the amount due, you can stop the billing period manually by clicking the Generate Invoice
          button</Trans>.
        </p>
        <p className='tag'>
          * <Trans i18nKey='description.invocieFreeTier'>Upon completion of your billing period, you will only receive
          an
          invoice for the usage exceeding the Free Tier</Trans>.
        </p>
      </Space>

      <Space direction='vertical' size={16}>
        <h3 className='h3 bold'><Trans i18nKey='title.notifications'>Notifications Settings</Trans></h3>
        <NotificationsSettings
          loading={loading}
          value={notifications}
          onChange={(v) => onUpdateProfile({ notifications: v })}
        />
      </Space>
    </Space>}

    <Space direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'><Trans i18nKey='title.email'>Email</Trans>{isTenant && <> (tenant) <InfoTooltip
        title='Your profile is connected to the main company account and governed by it'/></>}</h3>
      <EmailAddress
        value={email}
        verified={emailVerified}
      />
    </Space>

    <Col xs={24} lg={6} xl={4}>
      <Space direction='vertical' size={16} className='space-items-full'>
        <h3 className='h3 bold'><Trans i18nKey='title.language'>Language</Trans></h3>
        <Select
          className='w-100'
          showSearch
          defaultValue={i18n.language || 'en'}
          optionFilterProp='children'
          onChange={changeLanguage}
        >
          {appLanguages.map(r => <Option key={r.code} value={r.code}>{r.label}</Option>)}
        </Select>
      </Space>
    </Col>

    {!isTenant && <Space direction='vertical' size={16}>
      <h3 className='h3 bold'><Trans i18nKey='title.team'>Team</Trans></h3>
      <Tenants tenants={tenants} />
    </Space>}

    {isSupport && <Space direction='vertical' size={16}>
      <SupportBlock profile={authProfile} loading={loading} auth={authForwarded} />
    </Space>}

    <ProfileDangerousActions
      loading={loading}
      passwordChangeRequested={passwordChangeRequested}
      onPasswordChangeRequest={onPasswordChangeRequest}
      onLogout={() => navigate('/logout')}
      onEmailChanged={onEmailChanged}
      onRemove={onProfileRemove}
      authProvider={provider}
    />
  </Space>;
}

Profile.propTypes = {
  authForwarded: PropTypes.object.isRequired,
  auth: PropTypes.object,
};
