import React from 'react';
import { Form, Select, InputNumber, Space, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from './Common/SimpleService';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import { validateTaskAttributesExpanded } from '../../utils/validation';
import { ErrorMessage } from '../../shared/Components/ErrorMessage.jsx';

const { Option } = Select;
const defaultAttributes = ['name', 'price', 'discount price', 'description', 'image', 'rating', 'reviews'];

export default function UniversalAIScraper() {
  const { t } = useTranslation();

  const queryLimitLabelWithTooltip = <Space align='center'>
    {t('title.pagesPerQueryLimit', 'Pages limit per one query')}
    <InfoTooltip
      title='Parameter sets the maximum number of pages per query, typically used in cases where the page incorporates pagination'/>
  </Space>;

  return (
    <SimpleService
      beta
      unitName='page'
      title='Universal AI-Powered Scraper'
      subTitle='Returns the data you need from any web page by using AI'
      serviceName='ai_scraper_service_v2'
      queriesLabel='Links to pages'
      queriesPlaceholder='https://www.apple.com/iphone/'
      learnMoreUrl='https://outscraper.com/universal-ai-scraper/'
      apiTag='Other-Services/paths/~1universal-scraper/get'
      limitType={null}
      validateTask={validateTaskAttributesExpanded}
      defaultParams={{ attributes: [], limit_per_query: 1 }}
      ExtraItems={({ updateTask, task, invalidFields }) => {
        function handleOnChange(attributes) {
          if (attributes.length > 0 && attributes[attributes.length - 1].length < 2) {
            return;
          }
          updateTask({ attributes } );
        }

        return  <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={6} xl={4}>
            <Form.Item required label='Attributes to parse'>
              <Select
                allowClear
                autoFocus
                showSearch
                mode='tags'
                maxTagCount={6}
                tokenSeparators={[',']}
                placeholder='Select or enter attributes to parse'
                value={task.attributes}
                onChange={handleOnChange}
                status={invalidFields?.includes('attributes') ? 'error' : ''}
              >{defaultAttributes.map(r => <Option key={r} value={r}>{r}</Option>)}</Select>
              {invalidFields?.includes('attributes') && (
                <ErrorMessage />
              )}
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={queryLimitLabelWithTooltip}
              wrapperCol={{
                xs: { span: 24 },
                lg: { span: 6 },
                xl: { span: 4 },
              }}
            >
              <InputNumber
                className='w-100'
                min={1}
                max={1000}
                step={1}
                value={task.limit_per_query}
                onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
              />
            </Form.Item>
          </Col>
        </Space>;
      }}
    />
  );
}
