import { Button, Space } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';

export default function TaskId({ id }) {
  console.log('id', id);
  if (!id) return null;
  const firstPart = id.slice(0, 4);
  const lastPart = id.slice(-3);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(id);
  };

  return (
    <Space align='start'>
      <span>{`${firstPart}...${lastPart}`}</span>
      <Button
        type='link'
        className='copy-id-btn noPadding'
        title='Click to copy ID'
        icon={<CopyOutlined />}
        onClick={copyToClipboard}
      />
    </Space>
  );
}

TaskId.propTypes = {
  id: PropTypes.string.isRequired,
};
