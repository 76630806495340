import { useMediaQuery } from 'react-responsive';

export const useMedia = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 992 });
  const isDesktop = useMediaQuery({ minWidth: 993 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  return {
    isMobile,
    isTablet,
    isDesktop,
    isMobileOrTable: isMobile || isTablet,
    isNotMobile,
  };
};
