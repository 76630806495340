import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import config from 'react-global-configuration';

import { setPaidUser } from '../../../utils/utils';
import TaskConfirmationInfo from './TaskConfirmationInfo';
import TaskConfirmationNotAllowed from './TaskConfirmationNotAllowed';
import { useDimensions } from '../../../hooks/useDimensions';

const queriesHardLimit = config.get('queriesHardLimit');
const DEFAULT_SPLIT = 1;

export default function TaskConfirmationModal({ open, onOk, onCancel, onRevalidate }) {
  const validationResult = useSelector(state => state.queriesReducer.taskValidationResult);
  const loading = useSelector(state => state.queriesReducer.loading);
  const { t } = useTranslation();
  const { height } = useDimensions();

  const {
    allowed = false, status = '', total_queries = 1, risky = false,
    estimates = {}, deposit_required,
  } = validationResult;
  const { cost, duration } = estimates;
  const emailUnverified = status === 'email_unverified';

  const [confirmed, setConfirmed] = useState(false);
  const [shake, setShake] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [splitInto, setSplitInto] = useState(DEFAULT_SPLIT);
  const split = splitInto > 1;
  const okDisabled = total_queries > queriesHardLimit && !split;

  let title = t('title.taskConfirmation', 'Confirm Your Task');
  if (confirmed) {
    if (status === 'open_invoices') {
      title = t('title.open_invoices', 'Close Open Invoices');
    } else if (status === 'email_unverified') {
      title = t('title.email_unverified', 'Please Verify Your Email Address');
    } else if (status === 'low_balance') {
      title = t('title.low_balance', 'Insufficient Remaining Credit to Execute Task: Please Add More Credits or Limit Task Scope');
    } else if (status === 'risky_task') {
      title = t('title.risky_task', 'Your Account is Too New to Run Such a Task. Add Credits or Limit Your Task Results');
    } else {
      title = t('title.creditless', 'Add Credits') + (risky ? '' : (' ' + t('title.creditlessVerify', 'or Connect Your Card')));
    }
  } else {
    if (status) setPaidUser(status === 'valid');
  }

  if (allowed && confirmed) {
    setConfirmed(false);
    onOk(splitInto, cost, duration);
    setSplitInto(DEFAULT_SPLIT);
  }

  useEffect(() => {
    if (!loading && confirmed && !allowed) {
      setShake(true);
      setTimeout(() => setShake(false), 500);
    }
  }, [loading]);

  function handleOk() {
    if (allowed) {
      setConfirmed(false);
      onOk(splitInto, cost, duration);
      setSplitInto(DEFAULT_SPLIT);
    } else {
      if (confirmed) onRevalidate();
      setConfirmed(true);
      setDisabled(!emailUnverified);
    }
  }

  function handleCancel() {
    setConfirmed(false);
    setSplitInto(DEFAULT_SPLIT);
    setDisabled(false);
    onCancel();
  }

  return (
    <Modal
      centered={height < 900}
      width={700}
      title={title}
      className={shake ? 'shake' : ''}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel} className='cancel'>
          <Trans i18nKey='action.cancel'>Cancel</Trans>
        </Button>,
        <Button key='submit' type='primary' className='confirm' loading={loading} disabled={disabled || okDisabled} onClick={handleOk}>
          {t('action.confirm', 'Confirm')}
        </Button>,
      ]}
    >
      {confirmed ? (
        <TaskConfirmationNotAllowed
          status={status}
          onActionCallback={() => setDisabled(false)}
          loading={loading}
          risky={risky || status === 'risky_task'}
          depositRequired={deposit_required}
        />
      ) : (
        <TaskConfirmationInfo splitInto={splitInto} setSplitInto={setSplitInto} handleOk={handleOk} />
      )}
    </Modal>
  );
}

TaskConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRevalidate: PropTypes.func.isRequired,
  enrichments: PropTypes.array,
};
