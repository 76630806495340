import React from 'react';
import PropTypes from 'prop-types';
import { Input, Space } from 'antd';

import InfoTooltip from '../../../../shared/Components/InfoTooltip';

export default function UULEInput({ value, onChange }) {
  function handleOnChange({ target }) {
    onChange(target.value);
  }

  return <Space align='center' className='first-space-item-full'>
    <Input
      placeholder='w CAIQICIhTmV3IFlvcmssIE5ZIDEwMDAzLCBVbml0ZWQgU3RhdGVz'
      value={value}
      onChange={handleOnChange}
    />
    <InfoTooltip title={<>Google UULE parameter is used to encode a place or an exact location into a code.<br/><br/> By using it you can see a Google result page like someone located at the specified location.</>}/>
  </Space>;
}

UULEInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
