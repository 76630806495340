import React from 'react';
import PropTypes from 'prop-types';
import { Input, message, Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import config from 'react-global-configuration';

import Mixpanel from '../../analytics/mixpanel';
import { useMedia } from '../../hooks/useMedia';
import InfoTooltip from '../../shared/Components/InfoTooltip';
const homeUrl = config.get('homeUrl');

export default function ReferralProgram({ userId, disabled, referred }) {
  const referrer = userId ? btoa(userId) : '';
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  function onClickFromInput() {
    onCopy();
  }

  function onCopy() {
    Mixpanel.track('Copy referral link');

    let referrerLink = document.querySelector('.referrer-link');
    referrerLink.select();
    document.execCommand('copy');

    message.success(t('description.copied', 'Copied to your clipboard'));
  }

  return <Space className='w-100' direction={isDesktop ? 'horizontal' : 'vertical'}>
    <Space className='first-space-item-full'>
      <Input
        readOnly
        className='referrer-link copieble w-100'
        value={`https://${homeUrl}/refer?referrer=${referrer}`}
        disabled={disabled}
        onClick={onClickFromInput}
      />
      <Button
        className='noPadding'
        title={t('title.copyToClipboard', 'Copy to Clipboard')}
        icon={<FontAwesomeIcon icon={faCopy}/>}
        onClick={onCopy}
      />
      <InfoTooltip title='Simply copy your referral link and share it with your friends'/>
    </Space>
    {referred && <span className='textNoWrap'>{t('title.referredUsers', 'Referred users')}: {referred.toLocaleString()}</span>}
  </Space>;
}

ReferralProgram.propTypes = {
  userId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  referred: PropTypes.number,
};
