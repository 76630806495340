import React from 'react';

import SimpleService from './Common/SimpleService';

export default function RedditCommentsScraper() {
  return (
    <SimpleService
      unitName='comment'
      title='Reddit Comments Scraper'
      subTitle='Returns comments'
      serviceName='reddit_comments'
      queriesPlaceholder='https://www.reddit.com/r/squidgame/comments/1hzfvu8/poor_456/'
      defaultParams={{ sort: 'best' }}
      recentCutoffSorting={'new'}
      sortingOptions={[
        { value: 'best', text: 'Best' },
        { value: 'top', text: 'Top' },
        { value: 'new', text: 'New' },
        { value: 'controversial', text: 'Controversial' },
        { value: 'old', text: 'Old' },
        { value: 'qa', text: 'Q&A' },
      ]}
    />
  );
}
