import axios from 'axios';
import { get } from 'lodash';
import config from 'react-global-configuration';
import i18n from '../i18n';

axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const instance = axios.create({ baseURL: `${window.location.protocol}//${config.get('apiDomain')}` });

instance.interceptors.response.use(response => {
  if (response.data.error) {
    throw new Error(response.data.errorMessage);
  }

  return response;
}, error => {
  if (!navigator.onLine) {
    throw new Error(i18n.t('errors.offline'));
  }

  if (!error.response) {
    throw new Error(i18n.t('errors.unreachable'));
  }

  if (error.response.status === 401) {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('email');

    const { pathname, search = '' } = window.location;
    window.location = `/login?redirect=${encodeURIComponent(pathname + search)}`;
  } else if (get(error, 'response.data.error') && get(error, 'response.data.errorMessage')) {
    throw new Error(error.response.data.errorMessage);
  } else {
    const tryAgainLater = i18n.t('errors.tryAgainLater');
    const statusMessages = {
      403: i18n.t('errors.forbidden'),
      404: i18n.t('errors.notFound'),
      405: i18n.t('errors.methodNotAllowed'),
      409: i18n.t('errors.conflict'),
      410: i18n.t('errors.gone'),
      429: `${i18n.t('errors.tooManyRequests')}. ${tryAgainLater}`,
      500: `${i18n.t('errors.internalError')}. ${tryAgainLater}`,
      502: `${i18n.t('errors.badGateway')}. ${tryAgainLater}`,
      503: `${i18n.t('errors.serviceUnavailable')}. ${tryAgainLater}`,
      504: `${i18n.t('errors.gatewayTimeout')}. ${tryAgainLater}`
    };

    const message = statusMessages[error.response.status] || `${i18n.t('errors.unexpected')}. ${tryAgainLater}`;
    throw new Error(message);
  }

  console.error('API Error:', error);
  return Promise.reject(error);
});

export default instance;
