import React from 'react';
import { Form, Select, InputNumber, Checkbox, Space, Col, Row } from 'antd';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { services } from '../../shared/data/services';
import SimpleService from './Common/SimpleService';
import RegionSelect from './Common/Google/RegionSelect';

const { Option } = Select;
const StyledButton = styled.button`
  color: #262626;
  text-decoration: underline;
`;

export default function Screenshoter() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.screenshoter.unit}
      title={services.screenshoter.name}
      subTitle={services.screenshoter.description}
      serviceName='screenshoter'
      queriesPlaceholder='https://outscraper.com&#10;https://outscraper.com/pricing'
      queriesLabel='URLs'
      apiTag='Other-Services/paths/~1screenshoter/get'
      enrichmentFields={services.screenshoter.input_fields}
      defaultParams={{ region: 'US', type: 'webp', width: 1200, height: 800, full_page: false }}
      ExtraItems={({ updateTask, task }) => {
        const { region, type, width, height, full_page } = task;

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={6} xl={4}>
            <Form.Item label='IP Region'>
              <RegionSelect value={region} onChange={(v) => updateTask({ region: v })}/>
            </Form.Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Form.Item label='Image Extension'>
              <Select
                value={type}
                onChange={(v) => updateTask({ type: v })}
              >
                <Option value='webp'>WEBP</Option>
                <Option value='png'>PNG</Option>
                <Option value='jpeg'>JPEG</Option>
                <Option value='pdf'>PDF</Option>
              </Select>
            </Form.Item>
          </Col>

          <Space direction='vertical' size={4} className='w-100'>
            <Col xs={24} lg={12} xl={8}>
              <Form.Item>
                <Row gutter={[16, 16]}>
                  <Col xs={24} lg={12}>
                    <Form.Item label='Width (px)'>
                      <InputNumber className='w-100' placeholder='1200' min={100} max={1920} value={width} onChange={(v) => updateTask({ width: v })}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label='Height (px)'>
                      <Space direction='vertical' className='w-100'>
                        <InputNumber className='w-100' disabled={full_page} placeholder='800' min={100} max={1080} value={height} onChange={(v) => updateTask({ height: v })}/>
                        <Checkbox
                          checked={full_page}
                          onChange={() => updateTask({ full_page: !full_page })}
                        >Full page</Checkbox>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>

            <Space size={4}>
              <label><Trans i18nKey='title.try'>Try</Trans>: </label>
              <StyledButton
                type='button'
                className='link-button'
                onClick={() => updateTask({ width: 1920, height: 1080, full_page: false })}
              >FHD,</StyledButton>
              <StyledButton
                type='button'
                className='link-button'
                onClick={() => updateTask({ width: 1280, height: 720, full_page: false })}
              > HD,</StyledButton>
              <StyledButton
                type='button'
                className='link-button'
                onClick={() => updateTask({ width: 640, height: 480, full_page: false })}
              > SD</StyledButton>
            </Space>
          </Space>
        </Space>;
      }}
    />
  );
}
