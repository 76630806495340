import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Space, Tooltip } from 'antd';
import config from 'react-global-configuration';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { toTitleCase } from '../../../../utils/utils';
import types from '../../../../shared/data/types.json';
import InfoTooltip from '../../../../shared/Components/InfoTooltip';
import { ErrorMessage } from '../../../../shared/Components/ErrorMessage.jsx';
import { useMedia } from '../../../../hooks/useMedia';

const { Option } = Select;

const StyledButton = styled.button`
  color: #262626;
  text-decoration: underline;
`;

function CategoriesSelect({ value = [], onChange, noFreeText, maxExactMatchValues = 25, error }) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const { isDesktop } = useMedia();

  function handleOnChange(selectedTypes) {
    let includesForeign = false;

    if (selectedTypes.length <= maxExactMatchValues && !selectedTypes.every(r => types.includes(r))) {
      includesForeign = true;
    }

    onChange(selectedTypes, includesForeign);
  }

  const handleDropdownVisibleChange = (open) => {
    if (!open) {
      setSearchValue('');
    }
  };

  return <Space size={4} direction='vertical' className='w-100'>
    <Space direction='vertical' size={[8, 0]} className='w-100'>
      <Space align='center' className='nobreak first-space-item-full'>
        <Tooltip
          placement='right'
          mouseEnterDelay={0.3}
          title={value.length > 4 ? value.join(', ') : null}
        >
          <Select
            allowClear
            autoFocus
            showSearch
            autoClearSearchValue={false}
            size='large'
            mode={noFreeText ? 'multiple' : 'tags'}
            maxTagCount={4}
            className='categories-select'
            tokenSeparators={[',', '\n']}
            placeholder={t('action.selectCategories', 'Select categories')}
            value={value}
            onChange={handleOnChange}
            searchValue={searchValue}
            onSearch={setSearchValue}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            status={error ? 'error' : ''}
          >{types.map(r => <Option key={r} value={r}>{toTitleCase(r)}</Option>)}
          </Select>
        </Tooltip>
        <InfoTooltip title={
          <>
            <Trans i18nKey='description.selectCategories'>
              Dropdown is based on the following&nbsp;
              <a target='_blank' rel='noopener noreferrer' href={config.get('googleMapsCategoriesUrl')}>
                list of categories
              </a>. You still can use any other categories that will work on Google Maps.<br/><br/>
              Sometimes Google adds other categories to your searches,
              please use <a href='https://outscraper.com/google-maps-data-scraper-filters/' target='_blank' rel='noopener noreferrer'>Filters</a> from Advanced Parameters to get only the exact categories.
            </Trans>
            {value && value.length > 0 &&<>
              <br/>
              <br/>
              Selected: {value.join(', ')}
            </>}
          </>
        }/>
      </Space>
      {error && (
        <ErrorMessage />
      )}
    </Space>
    <Space direction={isDesktop ? 'horizontal' : 'vertical'}>
      <Space wrap='wrap'>
        <label>
          <Trans i18nKey='title.try'>Try</Trans>:
        </label>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(['doctor'])}
        >Doctor,</StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(['restaurant'])}
        >Restaurant,</StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange([
            'restaurant', 'bar', 'coffee shop', 'pizza restaurant', 'fast food restaurant', 'ice cream shop', 'bakery',
            'sandwich shop', 'bar & grill', 'cafe', 'juice shop', 'steak house', 'chocolate shop',
            'donut shop', 'dessert shop', 'seafood restaurant', 'donut shop',
          ])}
        >F&B,</StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(types.slice(0, 250))}
        >Top 250,</StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(types.slice(0, 500))}
        >Top 500,</StyledButton>
      </Space>
      <a href={config.get('googleMapsCategoriesUrl')} target='_blank' rel='noopener noreferrer'>
        <Trans i18nKey='link.allCategories'>Check out all categories</Trans>
      </a>
    </Space>
  </Space>;
}

CategoriesSelect.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  noFreeText: PropTypes.bool,
  maxExactMatchValues: PropTypes.number,
  error: PropTypes.bool,
};

export default React.memo(CategoriesSelect, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value && prevProps.error === nextProps.error;
});
