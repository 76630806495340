import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

export default function TagsSelect({ value, onChange }) {
  const defaultTags = ['demo task'];
  const { t } = useTranslation();

  function handleChange(tags) {
    onChange(tags.slice(-5));
  }

  return <>
    <Select
      allowClear
      mode='tags'
      maxTagCount={1}
      tokenSeparators={[',']}
      value={value}
      placeholder={t('description.enterTags', 'Enter tags')}
      onChange={handleChange}
    >
      {defaultTags.map((tag, index) => (<Select.Option key={index} value={tag}>{tag}</Select.Option>))}
    </Select>
  </>;
}

TagsSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
};
