import React, { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Select, Form, Space } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import InfoTooltip from '../../../shared/Components/InfoTooltip';
import { services } from '../../../shared/data/services';
import { usePrevious } from '../../../hooks/usePrevious';

const getColumnValue = (column, service) => service === 'domains_service' ? column : `.${column}`;

function OutputColumnsFormItem({ value, onChange, defaultFields, enrichments = [] }) {
  const isFirstRender = useRef(true);
  const [columnlessEnrichments, setColumnlessEnrichments] = useState([]);
  const { output_columns: outputColumns = [] } = value;
  const { t } = useTranslation();

  let selectableOptions = [ ...defaultFields.map(el => ({ label: el, value: el })) ];
  enrichments.forEach(e => {
    const currentEnrichment = services[e];
    if (currentEnrichment) {
      const outputFields = currentEnrichment.output_fields || [];
      const mappedOptions = outputFields.map(el => ({
        label: `${el} (${t(`title.${currentEnrichment.name}`, currentEnrichment.name) || e})`,
        value: getColumnValue(el, e),
      }));
      selectableOptions.push(...mappedOptions);
    }
  });
  const prevEnrichments = usePrevious(enrichments);

  useEffect(() => {
    if (isEmpty(outputColumns)) {
      if (isFirstRender.current) isFirstRender.current = false;
      return;
    }

    if (isEmpty(prevEnrichments) && isEmpty(enrichments)) {
      if (isFirstRender.current) isFirstRender.current = false;
      return;
    }

    const addedEnrichments = prevEnrichments ? enrichments.filter(x => !prevEnrichments.includes(x)) : enrichments;
    const removedEnrichments = prevEnrichments ? prevEnrichments.filter(x => !enrichments.includes(x)) : [];

    const removedColumns = new Set();
    removedEnrichments.forEach(e => {
      services[e].output_fields.forEach(column => {
        removedColumns.add(getColumnValue(column, e));
      });
    });

    const newOutputColumns = outputColumns.filter((c) => !removedColumns.has(c));
    addedEnrichments.forEach(e => {
      newOutputColumns.push( ...services[e].output_fields.map(c => getColumnValue(c, e)) );
    });

    if (isFirstRender.current) {
      isFirstRender.current = false;
      updateColumnlessEnrichments(outputColumns);
      return;
    }

    onOutputColumnsChange(newOutputColumns);

  }, [enrichments]);

  function onOutputColumnsChange(output_columns) {
    updateColumnlessEnrichments(output_columns);
    onChange({ ...value, output_columns: [...new Set(output_columns)] });
  }

  function updateColumnlessEnrichments(outputColumns) {
    if (!isEmpty(outputColumns)) {
      const servicesWithoutSelectedColumns = [];

      enrichments.forEach(e => {
        const expectedColumns = services[e].output_fields.map(c => getColumnValue(c, e)) || [];
        if (!outputColumns.some(c => expectedColumns.includes(c))) {
          servicesWithoutSelectedColumns.push(services[e].name || e);
        }
      });

      setColumnlessEnrichments(servicesWithoutSelectedColumns);
    } else {
      setColumnlessEnrichments([]);
    }
  }

  const returnColumnsLabelWithTooltip = <Space>
    {t('title.returnColumns', 'Columns to return (all if not selected)')}
    <InfoTooltip title={<Trans i18nKey='description.returnColumns'>
      Allows returning only specific columns.
      <br/><br/>
      Make sure all the necessary columns are selected, especially if using enrichments.
      <br/><br/>
      Leave empty to return all.
    </Trans>}/>
  </Space>;

  return <>
    <Form.Item label={returnColumnsLabelWithTooltip}>
      <Select
        allowClear
        mode='multiple'
        placeholder={t('placeholder.returnColumns', 'Select specific columns to return, leave empty to return all')}
        options={selectableOptions}
        value={outputColumns}
        maxTagCount={2}
        onChange={onOutputColumnsChange}
      />
      {!isEmpty(columnlessEnrichments) && <>
        <br/>
        <br/>
        <p className='warning'>
          <Trans i18nKey='description.returnColumnsError'>
            No columns selected for the following enrichment(s)
          </Trans>:&nbsp;
          {columnlessEnrichments.map(s => t(`title.${s}`, s)).join(', ')}
        </p>
      </>}
    </Form.Item>
  </>;
}

OutputColumnsFormItem.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultFields:  PropTypes.array.isRequired,
  enrichments: PropTypes.array,
};

export default React.memo(OutputColumnsFormItem, (prevProps, nextProps) => {
  const { output_columns: prevOutputColumns = [] } = prevProps.value;
  const { output_columns: nextOutputColumns = [] } = nextProps.value;

  return (
    prevOutputColumns.length === nextOutputColumns.length &&
    prevOutputColumns.every((el, i) => el === nextOutputColumns[i]) &&
    prevProps.enrichments === nextProps.enrichments &&
    prevProps.enrichments.every((el, i) => el === nextProps.enrichments[i])
  );
});
